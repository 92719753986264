import { CustomerService, NewsModel } from 'src/app/core/api/v1';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private messagesSubject: BehaviorSubject<NewsModel[]> = new BehaviorSubject<NewsModel[]>([]);
  private unreadMessagesSubject: BehaviorSubject<NewsModel[]> = new BehaviorSubject<NewsModel[]>([]);
  
  constructor(private customerService: CustomerService) { }

  getMessages(): Observable<NewsModel[]> {
    return this.messagesSubject.asObservable();
  }

  getAllMessages(): Observable<NewsModel[]> {
    return this.messagesSubject.asObservable();
  }

  getUnreadMessages(): Observable<NewsModel[]> {
    return this.unreadMessagesSubject.asObservable();
  }

  async markAsRead(messageId: string) {
    return this.customerService.apiCustomerMarkMessageReadPut(messageId).subscribe(() => {
      this.fetchMessages();
    });
  }
  async markAsUnread(messageId: string) {
    return this.customerService.apiCustomerMarkMessageUnreadPut(messageId).subscribe(() => {
      this.fetchMessages();
    });
  }

  fetchMessages(): void {
    this.customerService.apiCustomerGetAllActiveNewsGet().subscribe(messages => {
      this.messagesSubject.next(messages);
      this.unreadMessagesSubject.next(messages.filter(message => !message.isRead));
    });
  }
}