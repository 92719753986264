import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GTService {

  constructor(private http: HttpClient) { }

  triggerStartSyncSingle(tenantId: string): Observable<any> {
    let url = environment.gtRoot
    if(!url) throw new Error('gtRoot is not defined');
    url = url.replace('[tenantId]', tenantId);
    return this.http.get(url, {responseType: 'text'});
  }
}