import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerModel } from 'src/app/core/api/v1';
import { StoreService } from 'src/app/core/services/store.service';
import { fadeInOut } from '../styles/animation';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, PERMISSIONS } from '../core/services/authentication.service';

@Component({
  selector: 'app-settings',
  template: `
    <div class="h-100 w-100" [@fadeInOut]>
      <div class="content">
        <h1 class="pageTitle"><i class="fas fa-cog fa-3x"></i> Settings</h1>
        <p class="my-4">
          The settings are specified during the registration process. Some
          settings, such as the contact address, can be changed here. The active
          fields can be changed and saved with Submit. The storage name and
          storage key grant direct access to the collected data. Pay attention
          to these access data. If these are compromised, please contact us for
          a new storage key.
        </p>
        <form class="card card-body col-12 col-md-8 offset-md-2">
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1">
                  <i class="fas fa-globe-americas"></i> Domain
                </label>
                <input
                  name="domain"
                  [(ngModel)]="customer.domain"
                  placeholder="new domain name"
                  type="text"
                  id="Domain"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="tenantId">
                  <i class="fas fa-globe-americas"></i> Tenant ID
                </label>
                <input
                name="tenantId"
                [(ngModel)]="customer.tenantId"
                type="text"
                id="Domain"
                readonly
                class="form-control-plaintext"
                />
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1"
                  ><i class="fas fa-building"></i> Company name
                </label>
                <input
                  name="company"
                  placeholder="new company"
                  [(ngModel)]="customer.company"
                  type="text"
                  id="Company"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1">
                  <i class="fas fa-user"></i> First Name
                </label>
                <input
                  name="firstName"
                  placeholder="new First Name"
                  [(ngModel)]="customer.firstName"
                  type="text"
                  id="FirstName"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1">
                  <i class="fas fa-user"></i> Last Name
                </label>
                <input
                  name="lastName"
                  placeholder="new Last Name"
                  [(ngModel)]="customer.lastName"
                  type="text"
                  id="LastName"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1"
                  ><i class="fas fa-envelope"></i> Contact email</label
                >
                <input
                  name="contactEmail"
                  placeholder="new Contact Email"
                  [(ngModel)]="customer.contactEmail"
                  type="email"
                  id="contactEmail"
                  class="form-control"
                />
              </div>
            </div>
          </div>
         
          <div class="row" *ngIf="showStorageDetails">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1">
                  <i class="fas fa-hdd"></i> Storage Name
                </label>
                <input
                  name="storageName"
                  placeholder="Storage Name"
                  [(ngModel)]="customer.storageName"
                  type="text"
                  id="StorageName"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="showStorageDetails">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label class="form-label" for="form1Example1">
                  <i class="fas fa-key"></i> Storage Key
                </label>
                <input
                  name="storageKey"
                  placeholder="Storage Key"
                  [(ngModel)]="customer.storageKey"
                  type="text"
                  id="StorageKey"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4 d-flex align-items-center">
                <label class="form-label" for="form1Example1">
                  <i class="fas fa-eye"></i> Show Storage Details
                </label>
                <button
                  type="button"
                  class="btn btn-secondary ml-auto"
                  (click)="showStorageDetails = !showStorageDetails"
                >
                  <i class="fas fa-eye"></i>
                </button>
              </div>
            </div>
          </div>
           <div class="row" *ngIf="isAdmin && customer.lastLoginDate && customer.lastLoginUserUPN">
            <div class="col-12">
            <label class="form-label">
              <i class="fas fa-calendar"></i> Last login 
            </label>
              <p class="m-0">Upn: {{customer.lastLoginUserUPN}}</p>
              <p>Date: {{customer.lastLoginDate}}</p>
            </div>
          </div>
          <!-- add commentary textarea -->
          <div class="row">
            <div class="col-12">
              <div class="form-outline mb-4">
                <label
                  class="form-label
                  "
                  for="form1Example1"
                  ><i class="fas fa-comment"></i> Commentary</label
                >
                <textarea
                  class="form-control"
                  rows="5"
                  [(ngModel)]="customer.comment"
                  [ngModelOptions]="{ standalone: true }"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-outline float-right">
                <button
                  class="btn btn-primary"
                  type="submit"
                  (click)="submit()"
                >
                  <i class="fas fa-save"></i> Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [
    `
      .form-label {
        display: flex;
        align-items: center;
      }

      .form-label i {
        margin-right: 0.5rem;
      }

      .form-control-plaintext {
        background-color: #f8f9fa;
      }
      .form-control-plaintext:focus {
        outline: none;
      }

      .input-group .form-control-plaintext {
        border-right: 0;
      }

      .input-group-append button {
        border-left: 0;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class SettingsComponent {
  showStorageDetails = false;
  customer: CustomerModel = {};
  isAdmin = false;
  constructor(
    private storeService: StoreService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
  ) {
    this.authService.requestPermission()
    this.authService.permission.subscribe((permission) => {
      if (permission.authenticationLevel == PERMISSIONS.Admin) {
        this.isAdmin = true;
      }
    });

  }

  ngOnInit() {
    this.spinner.show();
    this.storeService.currentCustomer.subscribe((customer) => {
      this.customer = customer;
      setTimeout(() => {
        this.spinner.hide();      
      }, 300);
    });
  }

  submit() {
    this.spinner.show();
    if(this.customer.tenantId && this.isAdmin) return this.updateCustomerAdmin();
    return this.updateCustomer();
  }

  async updateCustomerAdmin() {
    try {
      this.spinner.show();
      this.toastr.info('Updating customer details');
      await this.storeService.requestCustomerUpdateAdmin(this.customer);
      this.spinner.hide();
      this.toastr.success('Customer details updated');
    } catch {
      this.spinner.hide();
      this.toastr.error('Customer details update failed');
    }
  }
  async updateCustomer() {
    try {
      this.spinner.show();
      this.toastr.info('Updating customer details');
      await this.storeService.requestCustomerUpdate(this.customer);
      this.spinner.hide();
      this.toastr.success('Customer details updated');
    } catch {
      this.spinner.hide();
      this.toastr.error('Customer details update failed');
    }
  }
}
