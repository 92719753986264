import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { fadeInOut } from '../../../styles/animation';

const items: Item[] = [
  {
    url: 'assets/img/getStarted/setup-1.png',
    description:
      '1. The request will be manually approved usually within one business day. You will receive an email with the subject "GT365:    Welcome! Pls. grant the app consent: "your domain". Send this  email (or the two links  <a href="https://bit.ly/gt365app1" target="_blank">app1</a> and    <a href="https://bit.ly/gt365app2)" target="_blank">app2</a>) to a    Global Administrator of your Microsoft 365 tenant. Both links must    be approved.',
  },
  {
    url: 'assets/img/getStarted/setup-1b.png',
    description:
      '1. The request will be manually approved usually within one business day. You will receive an email with the subject "GT365:    Welcome! Pls. grant the app consent: "your domain". Send this  email (or the two links  <a href="https://bit.ly/gt365app1" target="_blank">app1</a> and    <a href="https://bit.ly/gt365app2)" target="_blank">app2</a>) to a    Global Administrator of your Microsoft 365 tenant. Both links must    be approved.',
  },
  {
    url: 'assets/img/getStarted/setup-2.png',
    description:
      '2. A Global Admin must open the links and confirm the app access. When working with multiple identities, we recommend to **open the link in a browser In-Private mode.',
    subTitle:
      'Sign-in with the Global Admin and the password (and additional sign-options if configured). After the login, click "Accept" theGovernance Toolkit 365 app.',
  },
  {
    url: 'assets/img/getStarted/setup-3.png',
    description:
      '3. The app only asks for the least privilege permissions. It needs to read directory data, groups and users and permissions for provisioning groups and to invite guests. You will be redirected to a confirmation page.',
  },
  {
    url: 'assets/img/getStarted/setup-4.png',
    description:
      '4. After the confirmation, you will see the Governance Toolkit 365 dashboard. Depending on the selected options, you will see different dashboards.',
  },
  {
    url: 'assets/img/getStarted/setup-5.png',
    description:
      '5. You can start using the Governance Toolkit 365 immediately.',
  },
];
@Component({
  selector: 'app-get-started',
  template: `
    
        <h1 class="pageTitle"><i class="fas fa-clipboard-check fa-3x"></i> Governance Toolkit 365</h1>
        <p class=" mb-4">
          The Governance Toolkit 365 (GT365) provides a compliance coverage
          across multiple workloads. The toolkit runs as Software-as-a-Service
          and automatically requests usage data from the Microsoft 365 services
          and delivers summarized and detailed information to custom users and
          administrators. Additionally, the Governance Toolkit 365 provides an
          API for provisioning a new Microsoft 365 Group or a new Microsoft
          Team. See a feature overview at
          <a href="https://governancetoolkit365.com/" target="_blank"
            >GovernanceToolkit 365</a
          >.
        </p>
        <h2 class="pageTitle my-4">Setup</h2>
        <p  class="">
          The GT365 is provided as SaaS solution. Depending on the planned
          usage, you can use the reporting part only, the newsletters and the
          API if required. The onboarding includes these steps.
        </p>
        <div class="row getStarted" *ngFor="let item of items; index as i">
          <div class="col-12" *ngIf="i > 0">
            <p [innerHTML]="item.description"></p>
          </div>
          <div class="col-12" *ngIf="i > 0">
            <div
              class="row  align-items-center px-5"
           
            >
              <img
                (click)="openImageModal(0)"
                [src]="items[0].url"
                alt="Alternate Text"
                class="img-fluid mr-4 mb-4"
                *ngIf="i == 1"
              />
              <img
                (click)="openImageModal(i)"
                [src]="item.url"
                alt="Alternate Text"
                class="img-fluid mb-4"
              />
              
            </div>
            <p class="mt-2 px-5" *ngIf="item.subTitle">
                {{ item.subTitle }}
              </p>
          </div>
        </div>
    <app-image-modal
      (closingModal)="closeImageModal($event)"
      class="modalBg"
      [class.active]="isModalOpen && activeIndex > -1"
      [activeIndex]="activeIndex"
      [items]="items"
    ></app-image-modal>
  `,
  styles: [''],
  animations: [fadeInOut],
})
export class GetStartedComponent implements OnInit {
  constructor(
    private cdRef: ChangeDetectorRef,
     private spinner: NgxSpinnerService,
     private authService: AuthenticationService
   ) {
     this.authService.requestPermission()
   }

  isModalOpen = false;
  activeIndex = -1;
  items: Item[] = items;

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();      
    }, 300);
  }
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  openImageModal(activeItemIndex: number) {
    this.isModalOpen = true;
    this.activeIndex = activeItemIndex;
  }
  closeImageModal($event: any) {
    this.isModalOpen = $event;
    this.activeIndex = -1;
  }
}
export interface Item {
  url: string;
  description: string;
  subTitle?: string;
}
