import { SolutionDTO } from '../core/api/v1/model/azureTemplateDTO';
import { ChangeDetectorRef, Component } from '@angular/core';
import { fadeInOut } from '../styles/animation';
import { SolutionModel, CustomerService } from '../core/api/v1';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../core/services/authentication.service';

@Component({
  selector: 'app-azure-solutions',
  template: `
    <div class="h-100 w-100">
      <div class="content h-100">
        <div>
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h1 class="pageTitle"> <i class="fas fas fa-cloud fa-3x"></i> Solutions</h1>
            <div class="col-4 d-flex">
              <div class="p-input-icon-right mr-2">
                  <i class="pi pi-search"></i>
                  <input type="text" pInputText [(ngModel)]="searchTerm" [(ngModel)]="searchTerm" (ngModelChange)="filterSolutions()"/>
              </div>
              
              <div class="d-flex align-items-center">
                <p-dropdown [options]="sortMethods" [(ngModel)]="sortMethod"  (onChange)="handleSort()" placeholder="Sort By" class="mr-2 custom-dropdown"></p-dropdown>
              <button (click)="handleChangeSortDirection()" class="sortIcon">
                <i class="fa fa-sort-down" *ngIf="isDesc"></i>
                <i class="fa fa-sort-up" *ngIf="!isDesc"></i>
              </button>  <!-- <button>
                  <i class="fas fa-sort"></i>
                </button>
                <ul>
                  <li *ngFor="let method of sortMethods">
                  {{method}}
                  </li>
                </ul> -->
              </div>
            </div>

          </div>
          <div class="d-flex flex-column" *ngIf="filteredSolutions.length > 0">
            <solution-component 
              *ngFor="let solution of filteredSolutions"
              [solution]="solution"
              (onDetailsClicked)="openDialog($event)"
            />             
          </div>
        </div>

      </div>
    </div>
    <p-dialog 
    *ngIf="selectedTemplate"
    [focusOnShow]="true"
    [focusTrap]="true"
    [(visible)]="dialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [maximizable]="true"
    [breakpoints]="{ '960px': '90vw' }"
    [style]="{ width: '75vw' }"
    (onHide)="handleCloseDialog()"
    contentStyleClass
    >
    <ng-template pTemplate="header">
      <div class="d-flex flex-column">
        <div>
          <h1 class="pageTitle"><i class="fas fa-cloud fa-3x"></i>{{ selectedTemplate.name }}</h1>
        </div>
        <div class="template-tags">
            <p-chip *ngFor="let tag of selectedTemplate.tags" class="mr-2 mb-2">{{ tag }}</p-chip>
        </div>
      </div>

    </ng-template>
            
            <div>
              <p [innerHTML]="selectedTemplate.shortDescription"></p>
            </div>
            <div>
              <p [innerHTML]="selectedTemplate.longDescription"></p>
            </div>              
            <div>
              <label for="" class="mb-0">Last Updated:</label>
              <p>{{selectedTemplate.timestamp | date:'mediumDate' }}</p>
            </div>
        <ng-template pTemplate="footer">
          <div class="d-flex justify-content-end">
              <button (click)="hideTemplateDetails()" class="btn btn-danger">Close</button>
              <button  class="btn btn-success" (click)="handleLink(selectedTemplate.link!)" *ngIf="selectedTemplate.isDeployable && selectedTemplate.link">
                Deploy to Azure
              </button>
              <button  class="btn btn-primary" (click)="handleLink(selectedTemplate.templateUrl!)" *ngIf="selectedTemplate.isDownloadable  && selectedTemplate.templateUrl">
                Download
              </button>
            </div>
        </ng-template>
      </p-dialog>
  `,
  styles: [
    `
      .sortIcon{
        padding: 5px 9px;
        border: none;
        color: white;
      }
      .solutionTitle{
        margin-bottom: 20px;
      }
      ul {
        list-style: none;
        padding: 0;
      }

      li {
        margin: 10px 0;
      }

      button {
        padding: 7px 15px;
        background-color: #0467b4;
        color: #fff;
        border: none;
        border-radius: 4px;
      }

      button:hover {
        background-color: #045796;
      }
      
      .template-box {
        border: 1px solid #4c4f5c;
        border-radius: 4px;
        padding: 15px;
        background-color: #2a2e3a;
        color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
        /* height:100%; */
        /* justify-content: space-between; */
      }

      .template-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .template-header h2 {
        font-size: 18px;
        margin: 0;
      }

      .template-date {
        color: #9e9e9e;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .tagsDiv{
        min-height: 2em;
      }
      .template-description {
        color: rgba(255,255,255, 0.7);
        font-size: 14px;
        fontweight: 400;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 4.6em;
        text-overflow: ellipsis;
      }

      .template-tags {
        display: flex;
        flex-wrap: wrap;
      }

      .template-tags span {
        background-color: #4c4f5c;
        border-radius: 4px;
        color: #9e9e9e;
        font-size: 12px;
        margin: 5px;
        padding: 5px 10px;
      }

      

      .selected-template-buttons {        
        position: absolute;
        height: 50px;
        bottom:0;
        left:0;
      }
      .p-dropdown{
        padding: 0
      }
    `,
  ],
  animations: [fadeInOut],
})

export class SolutionsComponent {
  constructor(
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService
  ) {
    this.authService.requestPermission()
  }

  selectedTemplate: SolutionModel | null;
  solutions: SolutionDTO[] = [];
  filteredSolutions: SolutionDTO[] = [];
  dialogVisible: boolean = false
  searchTerm: string = '';
  sortMethod: SortMethod = 'Name';
  isDesc: boolean = false;
  sortMethods: SortMethod[] = ['Name','Date']
  isFilterDropdownOpen: boolean = false;
  openDialog(template: SolutionModel) {
    this.selectedTemplate = template;
    this.dialogVisible = true;
  }
  handleCloseDialog() {
    this.dialogVisible = false;
  }
  ngOnInit() {
    this.getAllsolutions(); 
  }
  ngAfterContentChecked(): void {
    this.cd.detectChanges();  
  }
  filterSolutions(){
    this.filteredSolutions = this.solutions.filter((solution) => {
      return solution.solution.toLowerCase().includes(this.searchTerm.toLowerCase()) || this.searchInTemplates(solution.templates).length > 0
    });
  }
  handleChangeSortDirection(){
    this.isDesc = !this.isDesc;
    this.handleSort();
  }

  hideTemplateDetails() {
    this.selectedTemplate = null;
  }
  

  handleLink(url:string){
    window.open(url, '_blank');
  }
  handleSort(){
    switch(this.sortMethod){
      case 'Name':
        this.sortByName();
        break;
      case 'Date':
        this.sortByDate();
        break;
    }
  }
  sortByName(){
    let sortedItems = this.filteredSolutions.sort((a,b) => {
      return a.solution.localeCompare(b.solution);
    })
    if(this.isDesc){
      sortedItems.reverse();
    }
    this.filteredSolutions = sortedItems;
  }
  sortByDate(){
    let sortedItems = this.filteredSolutions.sort((a,b) => {
      const aDate = this.getLatestDate(a.templates)
      const bDate = this.getLatestDate(b.templates)
      return new Date(aDate!).getTime() - new Date(bDate!).getTime();
    })
    if(this.isDesc){
      sortedItems.reverse();
    }
    this.filteredSolutions = sortedItems;
  }
  searchInTemplates(templates: SolutionModel[]){
    return templates.filter((template) => {
      return template.name?.toLowerCase().includes(this.searchTerm.toLowerCase()) || template.shortDescription?.toLowerCase().includes(this.searchTerm.toLowerCase()) || template.longDescription?.toLowerCase().includes(this.searchTerm.toLowerCase()) || template.tags?.some((tag) => {
        return tag.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    })
  }
  getLatestDate(solutions:SolutionModel[]){
      const sortedDate = solutions.sort((a,b) => {
        return new Date(a.timestamp!).getTime()  - new Date(b.timestamp!).getTime();
      })
      return sortedDate[0].timestamp;
  }
  async getAllsolutions() {
    try {
      this.spinner.show();
      this.customerService
      .apiCustomerGetAllSolutionsGet().subscribe((solutions) => {
        this.solutions = solutions as SolutionDTO[];        
        this.filterSolutions();
        this.spinner.hide();
      });
    } catch (error) {
      this.spinner.hide();
    }
  }
}

type SortMethod = 'Name'  | 'Date' ;