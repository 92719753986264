import { Injectable } from '@angular/core';
import { CustomerService } from '../api/v1/api/customer.service';
import { BehaviorSubject } from 'rxjs';
import {
  AdminService,
  CustomerModel,
} from '../api/v1';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(
    private customerService: CustomerService,
    private adminService: AdminService
  ) {}

  //#region States

  private _currentCustomer = new BehaviorSubject<CustomerModel>(
    {} as CustomerModel
  );
  private _allCustomers = new BehaviorSubject<CustomerModel[]>(
    [] as CustomerModel[]
  );

  //#endregion

  //#region Actions

  // Customer - Customer
  async requestCustomer(): Promise<CustomerModel> 
  {
    return this.customerService.apiCustomerGetCustomerGet().toPromise();
  }
  async requestCustomerUpdate(
    CustomerModel: CustomerModel
  ): Promise<HttpResponse<any>> {
    return this.customerService
      .apiCustomerUpdateCustomerPut(CustomerModel, 'response')
      .toPromise();
  }

  // Admin - Customer
  async updateCustomer(customer: CustomerModel): Promise<void> {
    this._currentCustomer.next(customer);
  }
  async updateAllCustomers(customers: CustomerModel[]): Promise<void> {
    this._allCustomers.next(customers);
  }
  async requestCustomerAdmin(tenantId: string): Promise<CustomerModel> {
    return  this.adminService.apiAdminGetCustomerGet(tenantId).toPromise();
  }
  async requestAllCustomersAdmin() {
      this.adminService.apiAdminGetCustomersGet().subscribe(data => this._allCustomers.next(data));     
  }
  async requestCustomerUpdateAdmin(
    customer: CustomerModel
  ): Promise<HttpResponse<any>> {
    return this.adminService
      .apiAdminUpdateCustomerPut(customer, 'response')
      .toPromise();
  }

  //#endregion

  //#region Getters

  get currentCustomer() {
    return this._currentCustomer;
  }

  get allCustomers() {
    // sorted ascending by name
    let customers = this._allCustomers;
    customers.forEach((e) =>
      e.sort((a, b) => (a.company || '').localeCompare(b.company || ''))
    );
    return customers;
  }

  //#endregion
}
