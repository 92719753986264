import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  template: `
    <a class="nav-link" (click)="toggleDropdown()">
      <ng-content select="[dropdown-button]"></ng-content>
    </a>

    <app-dropdown-menu *ngIf="isOpen" [size]="size">
      <ng-content select="[dropdown-menu]"></ng-content>
    </app-dropdown-menu>
  `,
  styles: [
    `
      :host {
        display: inherit;
      }

      .nav-link {
        cursor: pointer;
      }
    `,
  ],
})
export class DropdownComponent implements OnInit {
  public isOpen: boolean = false;
  @Input() size: string = 'md';
  @HostBinding('class.nav-item') hasNavItem: boolean = true;
  @HostBinding('class.dropdown') hasDropdown: boolean = true;
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.dropdownElement.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  constructor(private dropdownElement: ElementRef) {}

  ngOnInit(): void {}

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
}
