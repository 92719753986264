// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production:false,
  // apiRoot: 'https://localhost:7030',
  apiRoot: 'https://backenddev.governancetoolkit365.com',
  clientId: '22e84de2-a563-41a3-b870-4516f18a5dad',
  gtRoot: 'https://gt365devapp.azurewebsites.net/api/StartSyncSingle/[tenantId]?code=QwV_tsQMqZs1iM5R4GYJV3KiNsdMt16fYDc9Squi7c8IAzFupl5Z4g=='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
