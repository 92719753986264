/**
 * Governance365AdminWeb_Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewsModel { 
    timestamp?: string | null;
    eTag?: object;
    date?: string | null;
    newsId?: string | null;
    message?: string | null;
    title?: string | null;
    isActive?: boolean;
    isRead?: boolean;
}

