import { Component } from '@angular/core';
import { fadeInOut } from '../../../styles/animation';
import {
  AuthenticationService,
} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-access-control',
  template: `
<div>
      <div class="mb-4">
          <h1 class="pageTitle">
              <i data-icon-name="AccessControl" aria-hidden="true"
                  class="ms-Icon root-32 serviceIconStyles-sidebarmenu fa-3x"></i>
              Access Control
          </h1>
          <p class="px-4 mt-4">
              Administrators can restrict which users in their Microsoft 365 tenant can use this app.<br><br>
              Microsoft Entra (Azure AD) provides several benefits for managing applications, including Single
              sign-on (SSO) for
              users, application management and provisioning, security and conditional access, reporting and
              monitoring,
              B2B and B2C collaboration and many more.<br><br>
              By default, when the app is granted by an admin, everyone in your organization has access to the
              app.<br>
              To restrict access only for specific users, follow these steps.</p>
          <ol >
              <li>Open the Entra Portal at <a href="https://portal.azure.com/"
                      target="_blank">https://portal.azure.com</a> as a Global Admin or as an App Admin.</li>
              <li>Navigate to <strong>Azure Active
                      Directory</strong>.</li>
              <li>Open the <strong>Enterprise Applications</strong>.</li>
              <li>Search for the <strong>Governance Toolkit 365 Administration</strong> app.</li>
              <li>Click on the app and open it.</li>
              <li>In the left menu, open the <strong>Properties</strong> menu.</li>
              <li>Change the <strong>Assignment required?</strong> switch from No to <strong>Yes</strong>. If this
                  option is set to yes, then
                  users and other apps or services must first be assigned this application before being able to
                  access it. That is exactly what we want to achieve.</li>
              <li>Optionally, you can set the <strong>Visible to users?</strong> switch from Yes to
                  <strong>No</strong> to control if this app shall be shown on the user´s "My Apps".
              </li>
              <li><strong>Save</strong> the properties.</li>
              <li>As next step, add users or - better - groups. Open <strong>Users and Groups</strong>.</li>
              <li><strong>Add the users or the group.</strong></li>
              <li>That´s it.</li>
              <li>Test it and open the web app as an authorized user.</li>
          </ol>
          <p class="px-4 mt-4">You can find more detailed description in our blog at <a
              href="https://blog.atwork.at/post/restrict-access-to-an-Azure-AD-application" target="_blank">How to
              restrict access to an Azure AD application</a>.<br><br>
          You see, as an admin, you can explicitly control who can use this app.
          Usually it makes sense that only certain administrators have access to this app. We therefore recommend
          restricting its use as described here.
          </p>
      </div>
</div>
  `,
  styles: [
    `
      .containerContent {
        width: 100%;
        height: 100%;
      }

      ol{
        padding-left: 55px;
      }
      li{
        margin-bottom: 5px;
      }

      .example-list {
        /* border: solid 1px #4c4f5c; */
        /* min-height: 60px; */
        /* background: #8f8f8f; */
        border-radius: 4px;
        overflow: hidden;
        display: block;
        padding: 0;
        margin: 0;
      }
      .noItem {
        text-align: center;
        font-size: 16px;
      }
      .listItem {
        background: #fff;
        width: 75%;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 4px;
        padding: 5px 10px;
        /* border-bottom: solid 1px rgba(255,255,255, 0.2); */
        align-items: end;
        display: flex;
        cursor: move;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14)
      }
      .searchInput {
        width: 75%;
        margin: auto;
        background: transparent;
        margin-bottom: 15px;
        border: none;
        border-bottom: 1px solid rgba(0,0,0, 0.2);
        padding: 5px 10px;
      }
      .searchInput:focus {
        outline: none;
      }
      .listItem.user {
        /* background: rgba(0,0,0, 0.2); */
      }
      .listItem.admin {
        box-shadow: 0 5px 5px -3px rgba(15, 106, 176, 0.2),
        0 8px 10px 1px rgba(15, 106, 176, 0.14)
        /* background: rgba(15, 106, 176, 0.5); */
        /* border: solid 1px rgba(15, 106, 176, 0.5); */
        /* color: #fff; */
      }
      .display {
        font-size: 16px;
      }
      span.id {
        font-size: 16px;
        margin-left: 10px;
      }
      .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14)
      }

      .cdk-drag-placeholder {
        opacity: 0;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

     

      .example-list.cdk-drop-list-dragging
        .listItem:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
      .textCentered {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class AccessControlComponent {
  constructor(
    private authService: AuthenticationService,
  ) {
    this.authService.requestPermission()
  }


  async ngOnInit() {
    // TP, removed
  }
}