import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService, PERMISSIONS,
} from './core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoreService } from './core/services/store.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  template: ` <router-outlet *ngIf="loaded"></router-outlet>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="large"
      color="#fff"
      type="ball-spin-clockwise"
      [fullScreen]="true"
    >
      <p style="color: white">Loading...</p>
    </ngx-spinner>`,
  styles: [
    `
      :host {
        width: 100vw;
        height: 100vh;
      }
    `,
  ],
})
export class AppComponent {
  title = 'Governance 365';
  loaded = false;
  constructor(
    private spinner: NgxSpinnerService,
    private storeService: StoreService,
    private router: Router,
    private authService: MsalService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.authService.handleRedirectObservable().subscribe({
      next: (result) => {   
       this.loaded = true;
       this.getCustomer();
      },
      error: (error) => console.log(error)
    });
    
    
  }
  async getCustomer(){
    try{
      const resp = await this.storeService.requestCustomer();     
      this.storeService.updateCustomer(resp);
      this.spinner.hide();
    }catch(error:any){
      console.log(error)
      this.spinner.hide();
      error.status === 403 ? this.router.navigate([PERMISSIONS.UnAuthorized]) : this.router.navigate([PERMISSIONS.UnApproved]);
    }
  }

}
