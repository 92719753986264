/**
 * Governance365AdminWeb_Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserModel { 
    timestamp?: string | null;
    eTag?: object;
    accountEnabled?: string | null;
    createdDateTime?: string | null;
    displayName?: string | null;
    employeeId?: string | null;
    externalUserState?: string | null;
    externalUserStateChangeDateTime?: string | null;
    hasLicenseAssigned?: boolean;
    id?: string;
    lastLoginDate?: string | null;
    lastNonInteractiveSignInRequestId?: string | null;
    lastSignInRequestId?: string | null;
    mail?: string | null;
    mailNickname?: string | null;
    showInAddressList?: string | null;
    signInSessionsValidFromDateTime?: string | null;
    userPrincipalName?: string | null;
    userType?: string | null;
}

