//angular icon component with input boolean value

import { Component, Input } from "@angular/core";

@Component({
    selector: 'bool-icon',
    template: `
      <i class="pi pi-check" [class]="value ? 'pi-check text-success' : 'pi-times text-danger'" style="font-size: 1rem;"></i>
    `,
  })
  export class BooleanIconComponent {
    @Input() value: boolean;
  }