import { Component } from '@angular/core';
import { fadeInOut } from '../../../styles/animation';
import {
  AuthenticationService,
} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-access-control',
  template: `
    <div>
        <div class="mb-4">
            <h1 class="pageTitle">
                <i data-icon-name="AccessControl" aria-hidden="true"
                    class="ms-Icon root-32 serviceIconStyles-sidebarmenu fa-3x"></i>
                Renew the app
            </h1>
            <div class="px-4 mt-4">
              <strong>Consent to the app</strong><br><br>
              Administrators must give consent to the GT365 app once.<br>
              For the first time, we ask the admins to confirm the two links to grant permissions to use the app.<br>
              <ol>
                  <li><a href="https://bit.ly/gt365app1">https://bit.ly/gt365app1</a></li>
                  <li><a href="https://bit.ly/gt365app2">https://bit.ly/gt365app2</a></li>
              </ol>
              <strong>BOTH app links must be granted.</strong><br><br>
              If the short link does not work in your environment, eventually if short urls are blocked, you can use the
              follogin long link versions.<br>
              <ol>
                <li><a
                        href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=f6108159-1168-475d-b3ca-be8104781bf8&response_type=code&resource=https://management.azure.com/&redirect_uri=https://register.governancetoolkit365.com/&response_mode=query&scope=openid&state=powerapps">https://login.microsoftonline.com/common/oauth2/authorize?client_id=f6108159-1168-475d-b3ca-be8104781bf8&response_type=code&resource=https://management.azure.com/&redirect_uri=https://register.governancetoolkit365.com/&response_mode=query&scope=openid&state=powerapps</a>
                </li>
                <li><a
                        href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=f6108159-1168-475d-b3ca-be8104781bf8&response_type=code&resource=https://analysis.windows.net/powerbi/api&redirect_uri=https://register.governancetoolkit365.com/&response_mode=query&scope=openid&state=powerbi">https://login.microsoftonline.com/common/oauth2/authorize?client_id=f6108159-1168-475d-b3ca-be8104781bf8&response_type=code&resource=https://analysis.windows.net/powerbi/api&redirect_uri=https://register.governancetoolkit365.com/&response_mode=query&scope=openid&state=powerbi</a>
                </li>
              </ol>
            </div>
            <div class="px-4 mt-4">
              <strong>Renew the app</strong><br><br>
              If you find that all data is no longer being collected in the GT365, please renew the two app
              permissions as described above.<br>
              It may be that the app permissions are no longer valid in the meantime.<br><br>
              If new features have been added to the GT365 which again require a new confirmation, please confirm the
              new permissions with the following link:<br>
              <ul>
                  <li><a href="https://bit.ly/gt365extapp">https://bit.ly/gt365extapp</a>, or use the long url</li>
                  <li><a
                          href="https://login.microsoftonline.com/common/adminconsent?client_id=f6108159-1168-475d-b3ca-be8104781bf8&redirect_uri=https://register.governancetoolkit365.com/">https://login.microsoftonline.com/common/adminconsent?client_id=f6108159-1168-475d-b3ca-be8104781bf8&redirect_uri=https://register.governancetoolkit365.com/</a>
                  </li>
              </ul>
            </div>
            <p class="px-4 mt-4">
                The GT365 is running daily. With the (renewed) application permissions, the tool is able to read data
                from theM36 tenant.<br>
                To restrict access to your tenant, admins can simply delete the Governance Toolkit 365 application in
                Microsoft Entra.<br>
                In case of questions please contact atwork.
            </p>
        </div>
    </div>
  `,
  styles: [
    `
      .containerContent {
        width: 100%;
        height: 100%;
      }

      ol{
        padding-left: 55px;
      }
      li{
        margin-bottom: 5px;
      }

      .example-list {
        /* border: solid 1px #4c4f5c; */
        /* min-height: 60px; */
        /* background: #8f8f8f; */
        border-radius: 4px;
        overflow: hidden;
        display: block;
        padding: 0;
        margin: 0;
      }
      .noItem {
        text-align: center;
        font-size: 16px;
      }
      .listItem {
        background: #fff;
        width: 75%;
        margin: auto;
        margin-bottom: 10px;
        border-radius: 4px;
        padding: 5px 10px;
        /* border-bottom: solid 1px rgba(255,255,255, 0.2); */
        align-items: end;
        display: flex;
        cursor: move;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14)
      }
      .searchInput {
        width: 75%;
        margin: auto;
        background: transparent;
        margin-bottom: 15px;
        border: none;
        border-bottom: 1px solid rgba(0,0,0, 0.2);
        padding: 5px 10px;
      }
      .searchInput:focus {
        outline: none;
      }
      .listItem.user {
        /* background: rgba(0,0,0, 0.2); */
      }
      .listItem.admin {
        box-shadow: 0 5px 5px -3px rgba(15, 106, 176, 0.2),
        0 8px 10px 1px rgba(15, 106, 176, 0.14)
        /* background: rgba(15, 106, 176, 0.5); */
        /* border: solid 1px rgba(15, 106, 176, 0.5); */
        /* color: #fff; */
      }
      .display {
        font-size: 16px;
      }
      span.id {
        font-size: 16px;
        margin-left: 10px;
      }
      .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
          0 8px 10px 1px rgba(0, 0, 0, 0.14)
      }

      .cdk-drag-placeholder {
        opacity: 0;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

     

      .example-list.cdk-drop-list-dragging
        .listItem:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
      .textCentered {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class RenewComponent {
  constructor(
    private authService: AuthenticationService,
  ) {
    this.authService.requestPermission()
  }

  async ngOnInit() {
    // TP, removed
  }
}