import { Component, OnInit } from '@angular/core';
import { CustomerModel } from 'src/app/core/api/v1';
import { StoreService } from 'src/app/core/services/store.service';
import { fadeInOut } from 'src/app/styles/animation';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-advanced-settings',
  template: `
    <div class="" [@fadeInOut]>
      <div class="row">
        <div class="col-12">
          <h1 class="pageTitle">
            <i class="fa fa-cog fa-3x"></i>
            Customer Settings
          </h1>
          <p class=" my-4">
          This section shows the customer tenant properties for GT365 and is for the provider only. A change will automatically update the corresponding feature.
          </p>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label>Data self hosted</label>
                <small class="text">Only set to On, if the customer is providing his own Azure Account. Then, the connection string must be updated manually in the GT365 Key Vault for this tenant. Usually, customers use GT365 as SaaS and therefore the default is Off.</small>
                <p-inputSwitch [(ngModel)]="customer.dataSelfHosted" (onChange)="updateCustomer()"></p-inputSwitch>
                
              </div>
              <div class="form-group">
                <label>Approved</label>
                <small class="text">On if this is an active GT365 customer. The GT365 function only processes data for this tenant if this switch is set to On. Set to Off if the customer no longer is using GT365. Default is Off, and On after an approval.</small>
                <p-inputSwitch [(ngModel)]="customer.approved" (onChange)="updateCustomer()"></p-inputSwitch>
              </div>
              <div class="form-group">
                <label>Is Productive</label>
                <small class="text">A flag for marking this customer tenant as active. Currently unused for billing. Default is off.</small>
                <p-inputSwitch [(ngModel)]="customer.isProductive" (onChange)="updateCustomer()"></p-inputSwitch>
                
              </div>
              <div class="form-group">
                <label>Is Billing</label>
                <small class="text">A flag for marking this customer tenant as active and to be billed for GT365. Currently unused for billing. Default is off.</small>
                <p-inputSwitch [(ngModel)]="customer.isBilling" (onChange)="updateCustomer()"></p-inputSwitch>                
              </div>
              <div class="form-group">
                <label>Is Premium Tenant</label>
                  <small class="text">A flag if this M365 tenant has a Microsoft Entra Premium license P1, P2, or Governance (see 
                    <a href="https://www.microsoft.com/en-us/security/business/microsoft-entra-pricing" target="_blank">https://www.microsoft.com/en-us/security/business/microsoft-entra-pricing</a>). GT365 takes this into account for some features (to be clarified and is eventually set automatically). Currently unused here. Default is off.
                  </small>
                  <p-inputSwitch [(ngModel)]="customer.premiumTenant" (onChange)="updateCustomer()"></p-inputSwitch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .text, label{
      display: inline-block;
      width: 100%

    }
    .cursor-pointer{
      cursor: pointer;
    }
    .text{      
      margin-bottom:10px;
    }
    label{
      margin-bottom: 0;
    }
  `],
  animations: [fadeInOut],
})
export class AdminAdvancedSettingsComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthenticationService
  ) {
    this.authService.requestPermission()
  }
  faCog = faCog;
  customer: CustomerModel;
  hoverState: string = 'normal';
  ngOnInit(): void {
    this.spinner.show();
    this.storeService.currentCustomer.subscribe((customer) => {
      this.customer = customer;
      this.spinner.hide();      
    });
  }

  async updateCustomer() {
    try {
      this.spinner.show();
      this.toastr.info('Updating customer');
      await this.storeService.requestCustomerUpdateAdmin(this.customer);
      this.spinner.hide();
      this.toastr.success('Customer updated');
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Error updating customer');
    }
  }
}
