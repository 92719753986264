import { Routes, RouterModule } from '@angular/router';
import { CoreComponent } from './core/core.component';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardComponent } from './pages/dashboard.component';
import { GetStartedComponent } from './pages/description/components/get-started.component';
import { SolutionsComponent } from './pages/solutions.component';
import { SettingsComponent } from './pages/settings.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminSolutionsComponent } from './pages/admin/pages/admin-solutions';
import { AdminNewsComponent } from './pages/admin/pages/admin-news.component';
import { AccessControlComponent } from './pages/description/components/access-control.component';
import { AdminAdvancedSettingsComponent } from './pages/admin/pages/admin-advanced-settings.component';
import { UnathorizedComponent } from './pages/unathorized.component';
import { PERMISSIONS } from './core/services/authentication.service';
import { NewsComponent } from './pages/news.component';
import { DescriptionComponent } from './pages/description/description.component';
import { RenewComponent } from './pages/description/components/renew.component';

export const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'description',
        component: DescriptionComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: 'getStarted',
            component: GetStartedComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'renew',
            component: RenewComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'accesscontrol',
            component: AccessControlComponent,
            canActivate: [MsalGuard],
          }
        ]

      },
      {
        path: 'solutions',
        component: SolutionsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'news',
        component: NewsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'accesscontrol',
        component: AccessControlComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: 'solutions',
            component: AdminSolutionsComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'news',
            component: AdminNewsComponent,
            canActivate: [MsalGuard],
          },
          {
            path: 'settings',
            component: AdminAdvancedSettingsComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: PERMISSIONS.UnAuthorized, component: UnathorizedComponent, data: { type: 'UNAUTHORIZED'} },
  { path: PERMISSIONS.UnApproved, component: UnathorizedComponent, data: { type: 'UNAPPROVED'} },
  { path: PERMISSIONS.NotAllowed, component: UnathorizedComponent, data: { type: 'NOT_ALLOWED'} },
  { path: '**', redirectTo: '' },
];

export const AppRoutingModule = RouterModule.forRoot(routes, { useHash: true });
