import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RolesService } from '../api/v1';
import { AuthenticationModel } from '../api/v1/model/authenticationModel';
import { BehaviorSubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private rolesService: RolesService, private router: Router, private msal: MsalService) {
    
  }

  //States
  private _permission = new BehaviorSubject<AuthenticationModel>({});
   _loading = new BehaviorSubject<boolean>(true);

  //Actions
  requestPermission() {
    this._loading.next(true);
   this.rolesService
      .apiRolesGetUserPermissionGet()
      .subscribe((result: AuthenticationModel) => {
        this._permission.next(result);
        switch (result.authenticationLevel) {
          case PERMISSIONS.UnAuthorized: this.router.navigate([PERMISSIONS.UnAuthorized]); break;
          case PERMISSIONS.UnApproved: this.router.navigate([PERMISSIONS.UnApproved]); break;
          case PERMISSIONS.NotAllowed: this.router.navigate([PERMISSIONS.NotAllowed]); break;
          default: return ;
        }
      });
      this._loading.next(false);
  }

  
  //Getters
  get permission() {
    return this._permission;
  }
  get loading() {
    return this._loading;
  }
}
export const PERMISSIONS = {
  Admin: 'Admin',
  UnAuthorized: 'UnAuthorized',
  UnApproved: 'UnApproved',
  Approved: 'Approved',
  NotAllowed: 'NotAllowed',
}
