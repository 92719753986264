
import { Component} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { fadeInOut } from 'src/app/styles/animation';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NewsModel } from '../core/api/v1';
import { NotificationService } from '../core/services/notifications.service.';



@Component({
  selector: 'app-news',
  template: `
   <div class="h-100 w-100" [@fadeInOut]>
      <div class="content">
        <h1 class="pageTitle"><i class="fas fa-newspaper fa-3x"></i>News</h1>
        <div class="row">
          <div class="col-12" >
            <div class="table shadow-lg " [class.active]="items && items.length > 0" >
              <div class="table-header">
                <div class="table-row">
                  <div class="table-cell" style="width: 32px;">#</div>
                  <div class="table-cell" style="flex: 1;">Title</div>
                  <div class="table-cell" style="flex: 1; max-width:100px;">Date</div>
                  <div class="table-cell text-center" style="flex: 1; max-width:100px;">IsRead</div>

                </div>
              </div>
              
              <div class="table-body">
                <div
                  
                  class="table-row"
                  *ngFor="let item of items; let i = index"
                  (click)="selectItem(item)"
                  [class.selected]="selectedItem == item"
                >
                  <div class="table-cell" style="width: 32px;">{{ i + 1 }}</div>
                  <div class="table-cell" title="{{ item.title }}" style="flex: 1;">{{ item.title }}</div>
                  <div class="table-cell" title="{{ item.date }}" style="flex: 1;  max-width:100px;">{{ item.date | date: "YYYY-MM-dd" }}</div>
                  <div class="table-cell d-flex justify-content-center"  style="flex: 1;  max-width:100px; "><bool-icon [value]="item.isRead!" /></div>

                </div>
              </div>
            </div>

          </div>
        </div>
        
      </div>
    </div>
    <app-notification-dialog [dialogVisible]="dialogVisible" [selectedItem]="selectedItem"  *ngIf="selectedItem" (onDialogVisibleChange)="hideDialog()" (onChangedRead)="selectedItem.isRead = $event"/>
  `,
  styles: [
    `
    .table{
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    .table.active{
      opacity: 1;
    }
    `
  ],
  animations: [fadeInOut],
})
export class NewsComponent {
  constructor(
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService

  ) {
    this.authService.requestPermission()
  }
  items: NewsModel[];
  selectedItem: NewsModel | undefined;
  dialogVisible = false

  async ngOnInit () {
    this.getAllNews();    
  }
  
  selectItem(item: NewsModel) {
    this.selectedItem = item;
    this.showDialog();
  }
  showDialog() {
    this.dialogVisible = true;
  }
  
  hideDialog() {
    this.dialogVisible = false;
  }
  
  async getAllNews() {
    this.spinner.show();
    this.notificationService.getAllMessages().subscribe((data) => {
      this.items = data;     
      this.spinner.hide();
    })
  }
}
