import { Component } from '@angular/core';

@Component({
  selector: 'app-admin',
  template: `
    <div class="h-100 w-100">
      <div class="content h-100">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
})
export class AdminComponent {}
