/**
 * Governance365AdminWeb_Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimelineModel { 
    eTag?: object;
    timestamp?: string | null;
    deactivatedUsers?: number;
    deletedUsers?: number;
    distributionGroups?: number;
    groups?: number;
    groupsWithDynamicMembership?: number;
    guestUsers?: number;
    internalUsers?: number;
    mailenabledSecurityGroups?: number;
    office365Groups?: number;
    securityGroups?: number;
    teams?: number;
    unlicensedUsers?: number;
    users?: number;
    yammerProvisionedOffice365Groups?: number;
    licensedUsers?: number;
}

