import { environment } from 'src/environments/environment';
import { VERSION } from './../../utils/version';
import { Component, HostBinding } from '@angular/core';
import { DateTime } from 'luxon';
import { colors } from '../../styles/colors';
import { fadeInOut } from 'src/app/styles/animation';

@Component({
  selector: 'app-footer',
  template: `<div class="float-right d-none d-sm-block" [@fadeInOut]>
      <b *ngIf="devMode">Development </b><b>Version</b> {{ appVersion }}
    </div>
    <strong [@fadeInOut]>
      <span>Copyright &copy; {{ currentYear }}</span>
      <a
        href="https://www.atwork-it.com/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0; color: ${colors.primaryLight}"
      >
        atwork gmbh</a
      >
      <span>.</span>
    </strong>
    <span [@fadeInOut]> All rights reserved.</span>`,
  styles: [
    `:host {
        display: block;
        background-color: ${colors.neutralLight4};
        color: ${colors.neutralLight3};
        padding: 1rem;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class FooterComponent {
  @HostBinding('class') classes: string = 'main-footer';
  public appVersion = VERSION;
  public devMode: boolean = !environment.production;
  public currentYear: string = DateTime.now().toFormat('y');

  constructor() {
  }
}
