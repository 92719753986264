import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Item } from 'src/app/pages/description/components/get-started.component';

@Component({
  selector: 'app-image-modal',
  template: `
    <div class="modalContent position-relative">
      <button (click)="closeModal()" class="closeButton">
        <i class="fa fa-times"></i>
      </button>
      <p-carousel
        [value]="items"
        [page]="activeIndex"
        [numScroll]="1"
        [numVisible]="1"
        [circular]="true"
      >
        <ng-template pTemplate="header" class="position-relative">
        </ng-template>
        <ng-template
          let-data
          pTemplate="item"
          class="align-items-center d-flex justify-content-center"
        >
          <div
            class="carousel-content"
            class="w-100 d-flex flex-column align-items-center"
          >
            <img [src]="data.url" alt="image" />
            <p
              class="text-center text-white mt-4"
              style=""
              [innerHTML]="data.description"
            ></p>
          </div>
        </ng-template>
      </p-carousel>
    </div>
    <!-- <button>
        <i class="fas fa-arrow-left" (click)="prevImage()"></i>
      </button>
      <div class="itemDiv">
        <img [src]="items[activeIndex].url" alt="image" />
        <p>{{items[activeIndex].description}}</p>
      </div>
      <button>
        <i class="fas fa-arrow-right" (click)="nextImage()"></i>
      </button> -->
  `,
  styles: [``],
})
export class ImageModalComponent {
  @Input() items: Item[];
  @Input() activeIndex: number;
  images: string[];
  @Output() closingModal = new EventEmitter<boolean>();
  constructor(private cdRef: ChangeDetectorRef) {}
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
    /* this.activeImageUrl = this.items[this.activeIndex].images[this.activeImageIndex];
    this.activeImageDescription = this.items[this.activeIndex].description; */
  }
  closeModal() {
    this.closingModal.emit(true);
  }
  nextImage() {
    if (this.activeIndex == this.items.length - 1) {
      return (this.activeIndex = 0);
    }
    return this.activeIndex++;
  }
  prevImage() {
    if (this.activeIndex == 0) {
      return (this.activeIndex = this.items.length - 1);
    }
    return this.activeIndex--;
  }
}
