import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state('void', style({ opacity: 0.5 })),
  transition('void <=> *', animate(1000)),
]);

export const slideInOut = trigger('slideInOut', [
  state('void', style({ height: 0, opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);

export const toggleHover = trigger('toggleHover', [
  state('normal', style({ transform: 'scale(1)' })),
  state('hover', style({ transform: 'scale(1.05)' })),
  transition('normal <=> hover', animate(200)),
]);

export const slideFromLeft = trigger('slideFromLeft', [
  state('void', style({ transform: 'translateX(-100%)', opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);

export const slideFromRight = trigger('slideFromRight', [
  state('void', style({ transform: 'translateX(100%)', opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);

export const slideFromTop = trigger('slideFromTop', [
  state('void', style({ transform: 'translateY(-100%)', opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);

export const slideFromBottom = trigger('slideFromBottom', [
  state('void', style({ transform: 'translateY(100%)', opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);

export const slideFromTopLeft = trigger('slideFromTopLeft', [
  state('void', style({ transform: 'translate(-100%, -100%)', opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);

export const slideFromTopRight = trigger('slideFromTopRight', [
  state('void', style({ transform: 'translate(100%, -100%)', opacity: 0 })),
  transition('void <=> *', animate(1000)),
]);
