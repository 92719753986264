import { AppState } from '../../store/state';
import { UiState } from '../../store/ui/state';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { StoreService } from '../../services/store.service';

const BASE_CLASSES = 'main-header navbar navbar-expand';
@Component({
  selector: 'app-header',
  template: `
    <app-customerdropdown #customerdropdown></app-customerdropdown>

    <ul class="navbar-nav ml-auto">
      <app-notifications></app-notifications>
      <li class="nav-item rounded">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-expanded="false"
          (click)="isDropdownOpen = !isDropdownOpen"
        >
          {{ setUserName() }}
        </a>
        <div class="dropdown" aria-labelledby="navbarDropdown"  [ngClass]="{ open: isDropdownOpen }">
          <a class="dropdown-item" href="#" (click)="logout(false)">Sign out</a>
        </div>
      </li>
    </ul>
  `,
  styles: [
    `.nav-item{
      position: relative;
    }
    .dropdown {
      position: absolute;
      top: 40px;
      right: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 10px #ccc;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s ease-in-out;
    }
    .dropdown.open {
      visibility: visible;
      opacity: 1;
    }`
  ],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;

  public ui: Observable<UiState>;

  public isDropdownOpen = false;
  @Input()
  public darkMode: boolean;
  constructor(
    private authService: MsalService,
    private store: Store<AppState>,
    private storeService: StoreService

  ) {}

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.navbarVariant}`;
    });
  }

  //darkMode


  setUserName() {
    let accounts = this.authService.instance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return accounts[0].username;
    }
    return '';
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
}
