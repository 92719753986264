import { Component } from '@angular/core';
import { StoreService } from 'src/app/core/services/store.service';
import {
  AdminService,
  AuthenticationModel,
  CustomerModel,
  CustomerService,
  TimelineModel,
} from 'src/app/core/api/v1';
import { fadeInOut } from 'src/app/styles/animation';
import {
  AuthenticationService, PERMISSIONS,
} from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

const lastTimeArray: TimelineObject[] = [
  {
    count: 0,
    name: 'Users',
    icon: 'fas fa-users',
  },
  {
    count: 0,
    name: 'Guest Users',
    icon: 'fas fa-user-check',
  },
  {
    count: 0,
    name: 'Unlicensed Users',
    icon: 'fas fa-user-friends',
  },
  {
    count: 0,
    name: 'Deactivated Users',
    icon: 'fas fa-user-slash',
  },
  {
    count: 0,
    name: 'Groups',
    icon: 'fas fa-user-friends',
  },
  {
    count: 0,
    name: 'Security Groups',
    icon: 'fas fa-user-shield',
  },
  {
    count: 0,
    name: 'Distribution Groups',
    icon: 'fas fa-envelope',
  },
  {
    count: 0,
    name: 'Groups with Dynamic Membership',
    icon: 'fas fa-users-cog',
  }
];
@Component({
  selector: 'app-dashboard',
  template: `
    <div class="h-100 w-100" [@fadeInOut]>
      <div class="content h-100" *ngIf="customer">
      <div >
        <h1 class="pageTitle">
          <i class="fab fa-windows fa-3x"></i> M365 Tenant
          
        </h1>
        <h3 style="font-weight: 600;">{{ customer.company }}</h3>
        <h3 class="tenantid" *ngIf="customer.tenantId">Tenant ID: {{ customer.tenantId }}</h3>
        <p class="">
          The Governance Toolkit 365 provides an overview of data from your M365
          tenant. This dashboard page shows the current number of users and
          groups at a glance. For updates in the GT365, check out the news in
          the menu above.
        </p>
        <div class="p-5">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 px-3" *ngFor="let card of lastTimelineArray">
                <div class="card">
                  <div class="card-body d-flex flex-column align-items-center">
                    <i class="{{card.icon}} fa-3x"></i>
                    <h5 class="card-title mt-3">{{card.name}}</h5>
                    <p class="card-text">
                      {{ card.count || '-' }}
                    </p>
                  </div>
                </div>
            </div>            
          </div>
        </div>
      </div>
      </div>
    </div>
  `,
  styles: [
    `
      .tenantid {
        font-size: 20px;
        color: blue;
      }


      .card {
        background-color: #fff;
        text-align: center;
        height: 100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
      }

      .card:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      }

      
      .container {
        padding: 40px;
      }
      
      
      
 /*      .col-md-3 {
        width: calc(33.333333% - 20px);
        margin-bottom: 20px;
      } */

   
      i.fa-windows {
        margin-right: 10px;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        text-align: start;
        margin-bottom: 40px;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class DashboardComponent {
  customer: CustomerModel;
  lastTimeline: TimelineModel = {};
  lastTimelineArray: TimelineObject[] = lastTimeArray;
  permission: AuthenticationModel
  constructor(
    private storeService: StoreService, // for global customer
    private authService: AuthenticationService, // for permission
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private adminService: AdminService
  ) {
    this.authService.permission.subscribe((permission) => {
      
      this.storeService.currentCustomer.subscribe((customer: CustomerModel) => {
        this.customer = customer;
        if(!this.customer) return; 
        if (this.customer.tenantId && permission.authenticationLevel)
          if (
            permission.authenticationLevel == PERMISSIONS.Admin
          ) {
            this.loadTimelineAdmin(this.customer.tenantId || '');
          } else {
            this.loadTimeline();
          }
      });
    })
  }


  ngOnInit() {
    // this.authService.requestPermission();
   
  }

  async loadTimeline() {
    try {
      this.spinner.show();
      await this.customerService
        .apiCustomerGetYearlyTimelineGet()
        .toPromise().then((res) => {
          this.lastTimeline = res;
          this.lastTimelineArray = this.setTimelineArray()
          this.spinner.hide();
        });
      
    } catch (e) {
      this.spinner.hide();
      console.log(e)
    }
  }
  async loadTimelineAdmin(tenantId: string) {
    try {
      this.spinner.show();
      await this.adminService
        .apiAdminGetYearlyTimelineGet(tenantId)
        .toPromise().then((res) => {
          this.lastTimeline = res;
          this.lastTimelineArray = this.setTimelineArray()
          this.spinner.hide();
        });
    } catch (e) {
      this.spinner.hide();
      console.log(e);
    }
  }
  setTimelineArray() {
    return this.lastTimelineArray.map((item) => this.setCardItemCount(item));
  }
  setCardItemCount( item: TimelineObject) {
    const {users, deactivatedUsers, groups, securityGroups, distributionGroups, groupsWithDynamicMembership, guestUsers, unlicensedUsers} = this.lastTimeline;

    switch (item.name) {
      case 'Users':
      item.count = users;
        break;
      case 'Deactivated Users':
      item.count = deactivatedUsers;
        break;
      case 'Guest Users':
      item.count = guestUsers;
        break;
      case 'Unlicensed Users':
      item.count = unlicensedUsers;
        break;
      case 'Groups':
      item.count = groups;
        break;
      case 'Security Groups':
      item.count = securityGroups;
        break;
      case 'Distribution Groups':
      item.count = distributionGroups;
        break;
      case 'Groups with Dynamic Membership':
      item.count = groupsWithDynamicMembership;
        break;
      default:
        break;
    }
    return item;
  }

}

interface TimelineObject {
  count?: number
  name: string
  icon: string
}



