import { DialogModule  } from 'primeng/dialog';

import { ImageModalComponent } from './core/components/image-modal.component';
//Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { uiReducer } from './core/store/ui/reducer';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'primeng/carousel';
//Components
import { AppComponent } from './app.component';
import { FailedComponent } from './pages/failed.component';
import { AdminComponent } from './pages/admin/admin.component';
import { CoreComponent } from './core/core.component';
import { DashboardComponent } from './pages/dashboard.component';
import { GetStartedComponent } from './pages/description/components/get-started.component';
import { SolutionsComponent } from './pages/solutions.component';
import { AccessControlComponent } from './pages/description/components/access-control.component';
import { SettingsComponent } from './pages/settings.component';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { CheckboxComponent } from './shared/checkbox.component';
import { AppRoutingModule, routes } from './routes';
import { FooterComponent } from './core/components/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { MenuSidebarComponent } from './core/components/menu-sidebar.component';
import { NotificationsComponent } from './core/components/header/components/NotificationsComponent';
import { CustomerDropDownComponent } from './core/components/header/components/customerdropdown.component';
import { AdminNewsComponent } from './pages/admin/pages/admin-news.component';
import { AdminSolutionsComponent } from './pages/admin/pages/admin-solutions';
import { AdminAdvancedSettingsComponent } from './pages/admin/pages/admin-advanced-settings.component';
import { UnathorizedComponent } from './pages/unathorized.component';
import { BooleanIconComponent } from './shared/icon.component';
import { NewsComponent } from './pages/news.component';
import { NotificationDialogComponent } from './core/components/messageModal.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SolutionComponent } from './pages/components/solution.component';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { DescriptionComponent } from './pages/description/description.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RenewComponent } from './pages/description/components/renew.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import * as api from './core/api/v1';
//Authentication
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId, //'f24cc7ad-1799-44bb-b2d7-b57ada980f6e', // Prod enviroment. Uncomment to use.
      authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}
export function apiConfigFactory(): api.Configuration {
  const params: api.ConfigurationParameters = {
    basePath: environment.apiRoot,
  };
  return new api.Configuration(params);
}
export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);
  protectedResourceMap.set(environment.apiRoot, [
    'api://' + environment.clientId + '/Api.Access',
  ]);
  protectedResourceMap.set('https://localhost:7030', [
    'api://' + environment.clientId + '/Api.Access',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}
/* export function EnvConfigurationFactory(httpBackend: HttpBackend) {

  return () => {
    let http = new HttpClient(httpBackend);
    return http.get<ConfigurationModel>(environment.apiRoot + 'Configuration')
      .toPromise()
      .then((resp) => {
        config = resp as ConfigurationModel;
        
       
      })
  }
} */
@NgModule({
  declarations: [
    AppComponent,
    //Pages
    CoreComponent,
    FailedComponent,
    AdminComponent,
    DashboardComponent,
    GetStartedComponent,
    SolutionsComponent,
    AccessControlComponent,
    SettingsComponent,
    //shaerd
    DropdownComponent,
    CheckboxComponent,
    FooterComponent,
    HeaderComponent,
    NotificationsComponent,
    CustomerDropDownComponent,
    MenuSidebarComponent,
    AdminNewsComponent,
    AdminSolutionsComponent,
    AdminAdvancedSettingsComponent,
    ImageModalComponent,
    UnathorizedComponent,
    BooleanIconComponent,
    NewsComponent,
    NotificationDialogComponent,
    SolutionComponent,
    DescriptionComponent,
    RenewComponent
  ],
  imports: [
    [api.ApiModule.forRoot(apiConfigFactory)],
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    StoreModule.forRoot({ ui: uiReducer }),
    CommonModule,
    BrowserAnimationsModule,
    NgChartsModule,
    UiSwitchModule,
    MatAutocompleteModule,
    NgxSpinnerModule,
    DragDropModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CarouselModule,    
    DialogModule ,
    ToastrModule.forRoot(),
    InputSwitchModule,
    CardModule,
    ChipModule,
    AutoCompleteModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
  ],
  exports: [DialogModule, InputSwitchModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
