import { NotificationsComponent } from './components/header/components/NotificationsComponent';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UiState } from './store/ui/state';
import { AppState } from './store/state';
import { ToggleSidebarMenu } from './store/ui/actions';
import { StoreService } from './services/store.service';
import { NotificationService } from './services/notifications.service.';
@Component({
  selector: 'app-core',
  template: `<app-header class="fixed-navbar"></app-header>
    <app-menu-sidebar class="sidebar-dark-primary" ></app-menu-sidebar>

    <router-outlet *ngIf="loaded"></router-outlet>
    <app-footer></app-footer>
    <div id="app-footer"></div> `,
})
export class CoreComponent implements OnInit {
  loaded = false
  @HostBinding('class') class = 'wrapper';
  public ui: Observable<UiState>;

  constructor(private renderer: Renderer2, private store: Store<AppState>, private authService: AuthenticationService, private notificationService: NotificationService) {
    this.authService.loading.subscribe((loading) => {
      this.loaded = !loading;
    });
    this.notificationService.fetchMessages();
  }
  ngOnInit() {
    
    this.authService.requestPermission();
    this.ui = this.store.select('ui');
    this.store.dispatch(new ToggleSidebarMenu());
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
    this.renderer.addClass(document.querySelector('app-root'), 'layout-fixed');

    this.ui.subscribe(
      ({ menuSidebarCollapsed, controlSidebarCollapsed, darkMode }) => {
        if (menuSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-open'
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-open'
          );
        }

        if (controlSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open'
          );
        } else {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open'
          );
        }

        if (darkMode) {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'dark-mode'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'dark-mode'
          );
        }
      }
    );
    // this.loaded = true;
  }
  ngAfterViewInit() {
    // this.loaded = true;
  }
}
