import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  AdminService,
  SolutionModel,
} from 'src/app/core/api/v1';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { fadeInOut } from 'src/app/styles/animation';

@Component({
  selector: 'app-admin-solutions',
  template: `
  <div class="" [@fadeInOut]>
    <div class="d-flex justify-content-between align-itemns-center">
      <h1 class="pageTitle"><i class="fas fa-cloud fa-3x"></i>Solutions</h1>
      <div class="d-flex justify-content-end align-items-center">
        <button
          type="button"
          class="btn btn-primary"
          (click)="handleAdd()"
        >
          New
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="handleEdit()"
          [disabled]="!selectedItem"
        >
          Edit
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="handleDelete()"
          [disabled]="!selectedItem"
        >
          Delete
        </button>
        <!-- <button
          type="button"
          class="btn btn-warning"
          (click)="resetForm()"
          [disabled]="!selectedItem"
        >
          Reset
        </button> -->
      </div>
    </div>
    <div class="row" [@fadeInOut]>
    <div class="col-12" >
          <div class="table shadow-lg" [class.active]="azureTemplates.length > 0" *ngIf="azureTemplates">
            <div class="table-header">
              <div class="table-row w-100">
                <div class="table-cell" style="width: 32px;">#</div>
                <div class="table-cell" style="flex: 1; min-width: 100px;">Name</div>
                <div class="table-cell" style="flex: 1; min-width: 100px;">Solution</div>
                <div class="table-cell" style="flex: 1;">File Name</div>
                <div class="table-cell" style="flex: 1; display:flex; justify-content:center; max-width:150px;">Downloadable</div>
                <div class="table-cell" style="flex: 1; display:flex; justify-content:center; max-width:150px;">Deployable</div>
              </div>
            </div>
            <div class="table-body">
              <div
                class="table-row" [class]="selectedItem === template ? 'selected' : ''"
                *ngFor="let template of azureTemplates; let i = index"                
                (keyup.enter)="selectAzureTemplate(template)"
                [tabindex]="i"
              (click)="selectAzureTemplate(template)"
              >
                <div class="table-cell" style="width: 32px;">{{ i + 1 }}</div>
                <div class="table-cell" title="{{ template.name }}" style="flex: 1;min-width: 100;">{{ template.name }}</div>
                <div class="table-cell" title="{{ template.solution }}" style="flex: 1;min-width: 100;">{{ template.solution }}</div>
                <div class="table-cell" style="flex: 1;">{{ template.templateId }}</div>
                <div class="table-cell align-items-center" style="flex: 1; display:flex; justify-content:center;  max-width:150px;">
                  <bool-icon [value]="template.isDownloadable!" *ngIf="!template.isDownloadable"/>
                  <button *ngIf="template.isDownloadable" (click)="handleLink(template.templateUrl!)" style="border:0;background: transparent;"><i class="fas fa-download text-primary" ></i></button>                  
                </div>
                <div class="table-cell" style="flex: 1; display:flex; justify-content:center;  max-width:150px;">
                  <bool-icon [value]="template.isDeployable!" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p-dialog [header]="mode + ' Solution'" [(visible)]="dialogVisible"  [modal]="true" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" [maximizable]="true" >
          <form
            [formGroup]="form"
            class="azure-templates-form"
            enctype="multipart/form-data"
            (submit)="handleSubmit($event)"
          >
            <div class="form-group">
              <label for="name">Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
              />
            </div>
            <div class="form-group">
              <label for="name">Solution</label>
              <input
                type="text"
                class="form-control"
                id="solution"
                formControlName="solution"
              />
            </div>
            <div class="form-group">
              <label for="shortDescription">Short Description</label>
              <input
                type="text"
                class="form-control"
                id="shortDescription"
                rows="3"
                formControlName="shortDescription"
                (input)="handleCheckShortDescriptionLength()"
              />
               <div class="d-flex justify-content-end w-100">
                  <span [class.text-danger]="shortDescriptionLength > maxLength">{{shortDescriptionLength}} </span>
                  <span> / {{maxLength}}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="longDescription">Long Description</label>
              <textarea
                class="form-control"
                id="longDescription"
                rows="4"
                formControlName="longDescription"
                (input)="handleCheckLongDescriptionLength()"
              ></textarea>
              <div  class="d-flex justify-content-end w-100">
                <span [class.text-danger]="longDescriptionLength > maxLength">{{longDescriptionLength}} </span>
                <span> / {{maxLength}}</span>
              </div>
            </div>
            <div class="form-group">
              <label for="tags">Tags</label>
              <input
                type="text"
                class="form-control"
                id="tags"
                formControlName="tags"
              />
            </div>
            <div class="form-group">
  
              <label for="file">File</label>
              <div class="d-flex align-items-center px-2">
                <label for="file" class="cursor-pointer mr-4"><i class="fas fa-file fa-2x text-secondary"></i></label>
                <span class="fileName">{{ selectedFile?.name || selectedFileName || 'No file selected'}} <button  type="button" *ngIf="selectedItem?.isDownloadable" (click)="handleLink(selectedItem?.templateUrl!)" style="border:0;background: transparent; padding:5px;"><i class="fas fa-download text-primary" ></i></button> </span>
              </div>
              <input
                type="file"
                class="form-control d-none"
                id="file"
                (change)="onFileChange($any($event.target).files[0])"
              />
            </div>
            <div class="fileProp" [class.visible]="selectedFile || selectedFileName">
              
              <div class="form-group" >
                  <!-- <input
                    type="checkbox"
                    class="form-check-input"
                    id="isDownloadable"
                    formControlName="isDownloadable"
                  /> -->
                  <div>
                    <label for="isDownloadable" >Is Downloadable</label>
                  </div>
                <p-inputSwitch id="isDownloadable"
                  formControlName="isDownloadable" [style]="{marginRight: '0.5em'}"></p-inputSwitch>
              </div>
              <div class="form-group">
                  <!-- <input
                    type="checkbox"
                    class="form-check-input"
                    id="isDeployable"
                    formControlName="isDeployable"
                  /> -->
                  <div>
                    <label for="isDeployable" >Is Deployable</label>
                  </div>
                  <p-inputSwitch id="isDeployable"
                  formControlName="isDeployable" [style]="{marginRight: '0.5em'}"></p-inputSwitch>
              </div>  
            </div>
            <div class="w-100 d-flex justify-content-end">
              <button 
                class="btn btn-primary" 
                [disabled]="isFormValid()">
                Submit
              </button>
            </div>
          </form>
        </p-dialog>
       <p-dialog header="Delete Solution {{selectedItem.name}}" [(visible)]="deleteDialogVisible" *ngIf="selectedItem" [modal]="true" [draggable]="false" [resizable]="false" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }">
         <p class="text-center" style="font-size: 18px;">Are you sure you want to delete?</p>
         <div class="d-flex justify-content-end">
            <button class="btn btn-primary mr-2" (click)="deleteAzureTemplateAdmin()">Delete</button>
            <button class="btn btn-danger" (click)="hideDialog()">Cancel</button>
         </div>
       </p-dialog>
    </div>
  </div>
  `,
  styles: [
    `
    .table{
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      .table.active{
        opacity: 1;
      }
    .form-group{
      margin-bottom: 0.5rem;
    }
    .azure-templates-form{
      width: 100%;
      font-size: 0.8em;
    }
      .azure-templates-form label {
        font-weight: bold;
        margin-bottom: 0.3rem;
      }
      .azure-templates-form h2 {
        margin-bottom: 1em;
      }
      .fileProp{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.2s linear;
        max-height: 0;
      }
      .fileProp.visible{
        visibility: visible;
        opacity: 1;
        max-height: 100px;
      }

      .d-flex.justify-content-between {
        margin-top: 1em;
      }

      .btn {
        margin-right: 0.5em;
      }
      .fileName{
        font-size: 1.2em;
        font-weight: semibold;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class AdminSolutionsComponent {
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private authService: AuthenticationService
  ) {
    this.authService.requestPermission()
  }
  mode: 'New' | 'Edit' = 'New';
  form: FormGroup = new FormGroup({});
  azureTemplates: SolutionModel[];
  selectedItem: SolutionModel | undefined;
  selectedFile: File | undefined;
  selectedFileName: string | undefined | null;
  shortDescriptionLength: number;
  longDescriptionLength:number;
  maxLength = 6000
  dialogVisible = false
  deleteDialogVisible = false
  ngOnInit() {
    this.getAllTemplates();
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      solution: ['', Validators.required],
      shortDescription: ['', Validators.required,],
      longDescription: ['', Validators.required,],
      tags: [''],
      isDeployable: [false],
      isDownloadable: [false],
      file: [''],
    });
    this.handleCheckLongDescriptionLength();
    this.handleCheckShortDescriptionLength();
  }



showDialog() {
  this.dialogVisible = true;
}
handleAdd(){
  this.selectedItem = undefined;
  this.resetForm();
  this.mode = 'New';
  this.showDialog();
}
isFormValid(){
  const formValidated = this.form.valid;
  const hasFile = this.selectedFile || this.selectedFileName != undefined;
  const shortDescriptionValid = this.shortDescriptionLength <= this.maxLength;
  const longDescriptionValid = this.longDescriptionLength <= this.maxLength;
  return !(formValidated && hasFile && shortDescriptionValid && longDescriptionValid);
}
handleEdit(){
  this.mode = 'Edit';
  this.showDialog();
  this.handleCheckLongDescriptionLength();
  this.handleCheckShortDescriptionLength();
}
handleDelete(){
  this.deleteDialogVisible = true;
}
handleSubmit(event: Event) {
  event.preventDefault();
  if (this.mode === 'New') {
    this.addAzureTemplateAdmin();
  } else {
    this.updateAzureTemplateAdmin();
  }
}

hideDialog() {
  this.dialogVisible = false;
  this.deleteDialogVisible = false;
}
  handleCheckShortDescriptionLength() {
    this.shortDescriptionLength = this.form.get('shortDescription')?.value.length || 0;
  }
  handleCheckLongDescriptionLength() {
    this.longDescriptionLength = this.form.get('longDescription')?.value.length || 0;
  }
  handleLink(url:string){;
    window.open(url, '_blank');

  }
 
  selectAzureTemplate(template: SolutionModel) {
    this.selectedItem = template;
    this.form.patchValue(template);
    this.selectedFileName = this.selectedItem.templateId;
    this.hideDialog()
  }
  onFileChange(files: File) {
    this.selectedFile = files;
  }
  
  async addAzureTemplateAdmin() {
    try {
      if (!this.selectedFile){
        this.toastr.error('Please select a file');
        return;
      } 
      this.spinner.show();
      const {name, solution, shortDescription, longDescription, tags, isDownloadable, isDeployable, file} = this.getFormValues();
      this.adminService
        .apiAdminCreateSolutionPost(
          name,
          shortDescription,
          longDescription,
          tags,
          isDeployable,
          isDownloadable,
          solution,
          file
        )
        .subscribe(data => {
          this.resetForm()
          this.spinner.hide();
          this.toastr.success('Template updated successfully');
          this.getAllTemplates();
          this.hideDialog();
      });
    } catch (err) {
      this.spinner.hide();
      console.error(err);
      this.toastr.error('Error creating template');
    }
  }
  getFormValues() {
    return {
      name: this.form.get('name')?.value,
      shortDescription: this.form.get('shortDescription')?.value,
      longDescription: this.form.get('longDescription')?.value,
      tags: this.getTagsAsString(this.form.get('tags')?.value),
      isDownloadable: this.form.get('isDownloadable')?.value,
      isDeployable: this.form.get('isDeployable')?.value,
      solution: this.form.get('solution')?.value,
      file: this.selectedFile,
    };
  }
  resetForm() {
    this.form.reset();
    this.selectedItem = undefined;
    this.selectedFile = undefined;
    this.selectedFileName = '';
  }
  getTagsAsString(tags?: string[] | string) {
    if(!tags) return '';
    if(tags.length == 0) return '';
    if(typeof tags === 'string') return tags;
    return tags.join(', ');
  }

  async updateAzureTemplateAdmin() {
    try {
      this.spinner.show();
      this.toastr.info('Updating template...;');
      const {name,solution, shortDescription, longDescription, tags, isDownloadable, isDeployable, file} = this.getFormValues();
      this.adminService
        .apiAdminUpdateSolutionPost(
          this.selectedItem?.id ?? '',
          this.selectedItem?.templateId ?? '',
          name,
          shortDescription,
          longDescription,
          tags,
          isDeployable,
          isDownloadable,
          solution,
          file
        ).subscribe(data => {
            this.resetForm()
            this.spinner.hide();
            this.toastr.success('Template updated successfully');
            this.getAllTemplates();
            this.hideDialog();
        });
        
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Template update failed');
    }
  }
  sanitizeFileType(fileType: string) {
    switch(fileType) {
      case 'image/jpeg':
        return 'jpg';
      case 'image/png':
        return 'png';
      case 'image/gif':
        return 'gif';
      case 'image/svg+xml':
        return 'svg';
      case 'image/webp':
        return 'webp';
      case 'application/pdf':
        return 'pdf';
      case 'application/msword':
        return 'doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.ms-powerpoint':
        return 'ppt';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      case 'application/vnd.oasis.opendocument.text':
        return 'odt';
      case 'text/html':
        return 'html';
        case 'application/zip':
        return 'zip';
        case 'application/json':
        return 'json';
      default:
        return '';
    }
  }
  async deleteAzureTemplateAdmin() {
    try {
      this.spinner.show();
      this.toastr.info('Deleting template...;');
      await this.adminService
        .apiAdminDeleteSolutionDelete(
          this.selectedItem?.templateId || ''
        )
        .toPromise();
      this.spinner.hide();
      this.selectedItem = {};
      this.toastr.success('Template deleted successfully');
      this.getAllTemplates();
      this.resetForm();
      this.hideDialog();
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Template deletion failed');
    }
  }
  async getAllTemplates() {
    try {
      this.spinner.show();
      const azureTemplates = await this.adminService
        .apiAdminGetAllSolutionsGet()
        .toPromise();
      this.azureTemplates = azureTemplates;
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
    }
  }
}

