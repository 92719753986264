import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SolutionModel } from "src/app/core/api/v1";
import { SolutionDTO } from "src/app/core/api/v1/model/azureTemplateDTO";

@Component({
    selector: 'solution-component',
    template: `
    <div>
      <h4 class="group-title">{{solution.solution}}</h4>
    </div>
    <div *ngIf="solution.templates.length > 0" class="row w-100">
      <div class="col-12 col-md-6 col-lg-4 mb-4 " *ngFor="let template of solution.templates">
        <p-card   styleClass="bg-gradient-dark text-light d-flex flex-column flex-grow-1" >           
          <ng-template pTemplate="header">
            <div class="px-3 pt-3">
              <h4 class="mb-0" style="font-size: 18px;">
                {{template.name}}
              </h4>
              <p class="template-date mb-2">
                Last updated: {{ template.timestamp | date:'mediumDate' }}
              </p>
              <div class="d-flex flex-wrap" style="height: 3rem">
                <p-chip *ngFor="let tag of template.tags" class="mr-2 mb-2">{{ tag }}</p-chip> 
              </div>
  
            </div>
          </ng-template>
          <ng-template pTemplate="content" styleClass="">
              <p class="text-ellipsis mb-0" [title]="template.shortDescription" [innerHTML]="template.shortDescription"></p>
            <!-- <div class="d-flex flex-wrap mb-2 tagsDiv">
              <div *ngFor="let tag of template.tags" class="px-2 py-1 rounded bg-light mr-2 font-weight-semibold">{{ tag }}</div>
            </div> -->
          </ng-template>
          <ng-template pTemplate="footer">
            <div class="d-flex justify-content-end">
              <button (click)="handleClickDetails(template)" class="btn btn-sm btn-light">Details</button>
              <button (click)="handleLink(template.templateUrl)" *ngIf="template.isDownloadable && template.templateUrl" class="btn btn-sm  btn-primary ml-2">
                Download
              </button>
              <button (click)="handleLink(template.link)" *ngIf="template.isDeployable && template.link" class="btn btn-sm btn-success ml-2">
                Deploy
              </button>
  
            </div>
          </ng-template>
        </p-card>
      </div>
    </div>
`,
    styles: [
      `
      .group-title {
        font-size: 1.5rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 1rem;
      }
      .text-ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-height: 2.8rem;

      }
      `
    ]
})
export class SolutionComponent {
    @Input() solution: SolutionDTO;
    @Output() onDetailsClicked = new EventEmitter<SolutionModel>();
    constructor() { }

    ngOnInit() { }
    handleLink(url:string){
      window.open(url, '_blank');    
    }
    handleClickDetails(template: SolutionModel){
      this.onDetailsClicked.emit(template);
    }
}