/**
 * Governance365AdminWeb_Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerModel { 
    approved?: boolean | null;
    comment?: string | null;
    company?: string | null;
    contactEmail?: string | null;
    dataSelfHosted?: boolean | null;
    domain?: string | null;
    firstName?: string | null;
    isBilling?: boolean | null;
    isProductive?: boolean | null;
    lastName?: string | null;
    premiumTenant?: boolean | null;
    tenantId?: string | null;
    totalGroups?: number | null;
    totalUsers?: number | null;
    storageName?: string | null;
    storageKey?: string | null;
    powerAppsActive?: boolean | null;
    powerBIActive?: boolean | null;
    version?: number | null;
    lastLoginDate?: string | null;
    lastLoginUserUPN?: string | null;
}

