import { AppState } from '../store/state';
import { UiState } from '../store/ui/state';
import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  AuthenticationService, PERMISSIONS,
} from '../services/authentication.service';
import { AuthenticationModel } from '../api/v1';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
  selector: 'app-menu-sidebar',
  template: `
    <div (mouseover)="submenusOpen = true" (mouseleave)="submenusOpen = false">

      <a [routerLink]="['/']" class="brand-link">
        <img
          src="assets/img/governance_toolkit_365.png"
          alt="AdminLTE Logo"
          class="brand-image img-circle"
          style="opacity: 0.8"
        />
        <span class="brand-text font-weight-light">Governance365</span>
      </a>
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2" style="overflow-y: hidden">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <!-- nested navigation links for each item in menu -->
            <li *ngFor="let item of menu" class="nav-item has-treeview">
              <div *ngIf="!item.subcategories" class="nav-link">
                <a [routerLink]="[item.path]" class="nav-link">
                  <div class="row">
                    <div [innerHTML]="item.icon" class=" col-2"></div>
                    <div class="col-8">
                      <p>
                        {{ item.name }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div *ngIf="item.subcategories " class="nav-link">
                <div class="nav-nolink row">
                  <div [innerHTML]="item.icon" class="col-2"></div>
                  <div class="col-8 hidedContent" [class.active]="submenusOpen">
                    <p>
                      {{ item.name }}
                    </p>
                  </div>
                </div>
                <ul style="list-style: none" class="hidedContent" [class.active]="submenusOpen" 
                >
                  <li *ngFor="let subitem of item.subcategories" class="nav-item">
                    <a [routerLink]="[subitem.path]" class="nav-link" >
                      <div class="row">
                        <div
                          [innerHTML]="subitem.icon"
                          class="col-1 offset-1"
                        ></div>
                        <div class="col-7 offset-1">
                          <p>
                            {{ subitem.name }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div><!-- Brand Logo -->
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .hidedContent {
        opacity: 0;
        max-height:0;
        transition: 0.4s ease-in-out all;
      }
      .hidedContent.active{
        opacity: 1;
        max-height: 500px;
      }

      .sidebar {
        background-color: #4c4f5c;
      }

      .brand-link {
        background-color: #4c4f5c;
      }
      .container {
        background-color: red;
        width: 100vh;
        height: 100vh;
      }

      .nav-link {
        padding-left: 0.5rem !important;
      }

      .nav-nolink {
        padding-left: 0.5rem !important;
        color: white;
      }
    `,
  ],
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public menu = MENU;
  public AuthenticationModel: AuthenticationModel;
  submenusOpen = false;
  constructor(
    private store: Store<AppState>,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.authenticationService.permission.subscribe(
      (permission: AuthenticationModel) => {
        if (
          permission.authenticationLevel &&
          permission.authenticationLevel != PERMISSIONS.Admin
        ) {
          this.menu = this.menu.filter((item) => item.name !== 'Admin');
        }
      }
    );
  }
}

export const MENU = [
  {
    name: 'Dashboard',
    path: '',
    icon: '<i data-icon-name="Home" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
    subcategories: null,
  },
  {
    name: 'Description',
    path: 'description',
    icon: '<i data-icon-name="getStarted" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
    subcategories: [
      {
        name: 'Get Started',
        path: 'description/getStarted',
        icon: '<i data-icon-name="News" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
        subcategories: null,
      },
      {
        name: 'Renew the app',
        path: 'description/renew',
        icon: '<i data-icon-name="Azure" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
        subcategories: null,
      },
      {
        name: 'Access Control',
        path: 'description/accesscontrol',
        icon: '<i data-icon-name="AccessControl" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
        subcategories: null,
      }
    ],
  },
  {
    name: 'Solutions',
    path: 'solutions',
    icon: '<i data-icon-name="Azure" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
    subcategories: null,
  },
  {
    name: 'News',
    path: 'news',
    icon: '<i data-icon-name="News" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
    subcategories: null,
  },
  {
    name: 'Settings',
    path: 'settings',
    icon: '<i data-icon-name="Settings" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
    subcategories: null,
  },
  // {
  //   name: 'Access Control',
  //   path: 'accesscontrol',
  //   icon: '<i data-icon-name="AccessControl" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
  // },
  {
    name: 'Admin',
    path: 'admin',
    icon: '<i data-icon-name="Admin" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
    subcategories: [
      {
        name: 'News',
        path: 'admin/news',
        icon: '<i data-icon-name="News" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
        subcategories: null,
      },
      {
        name: 'Solutions',
        path: 'admin/solutions',
        icon: '<i data-icon-name="Azure" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
        subcategories: null,
      },
      {
        name: 'Customer Settings',
        path: 'admin/settings',
        icon: '<i data-icon-name="AdvancedSettings" aria-hidden="true" class="ms-Icon root-32 serviceIconStyles-sidebarmenu"></i>',
      },
    ],
  },
];
