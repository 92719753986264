/**
 * Governance365AdminWeb_Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SolutionModel { 
    id?: string | null;
    timestamp?: string | null;
    templateId?: string | null;
    name?: string | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    tags?: Array<string> | null;
    link?: string | null;
    isDeployable?: boolean;
    isDownloadable?: boolean;
    solution?: string | null;
    templateUrl?: string | null;
}

