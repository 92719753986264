import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Component } from '@angular/core';
import { AuthenticationModel, NewsModel } from 'src/app/core/api/v1';
import { NotificationService } from 'src/app/core/services/notifications.service.';
import { Router } from '@angular/router';


@Component({
  selector: 'app-notifications',
  template: `
    <div class="notification-icon" (click)="toggleDropdown()">
      <i class="fas fa-bell"></i>
      <span class="badge badge-danger">{{ items.length }}</span>
    </div>
    <div
      class="dropdown rounded"
      [ngClass]="{ open: isDropdownOpen }"
      *ngIf="items"
    >
      <ul class="list-group">
        <li class="list-group-item border-none cursor-pointer d-flex flex-column py-2 px-3" *ngFor="let item of items.slice(0, 5); index as i" (click)="openMessage(item)">
          <p class="mb-0 text-ellipsis" style="font-weight: 600; font-size:18px">{{ item.title }}</p>
          <p class="m-0 text-ellipsis" style="font-size:16px">{{item.message}}</p>
          <p class="m-0 date">Created on: {{ item.date | date :"YYYY-MM-dd" }}</p>
        </li>
        <li class="list-group-item border-none">
          <div class="d-flex justify-content-center w-100">
            <a class="btn btn-primary w-100" (click)="closeDropdown()" [routerLink]="['news']">Read All</a>
          </div>
        </li>
      </ul>
    </div>
    <app-notification-dialog [dialogVisible]="dialogVisible" [selectedItem]="selectedItem" *ngIf="selectedItem" (onDialogVisibleChange)="hideDialog()">
      

  `,
  styles: [
    `
    .text-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .cursor-pointer{
      cursor: pointer;
      }
      .notification-icon {
        cursor: pointer;
      }

      .notification-icon .badge {
        top: -5px;
        right: -5px;
      }
      .dropdown {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 300px;
        background-color: #fff;
        box-shadow: 0px 0px 10px #ccc;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s ease-in-out;
      }
      .dropdown.open {
        visibility: visible;
        opacity: 1;
      }
      .list-group{
        max-height: 800px;
        overflow-y: auto;
      }
      .list-group-item h5 {
        font-weight: bold;
      }
      
      .list-group-item p {
        font-size: 0.8em;
      }


      .date {
        opacity: 0.7;
      }
    `,
  ],
})
export class NotificationsComponent {
  constructor(private notificationsService: NotificationService, private router: Router) {
  }
  isDropdownOpen = false;
  items: NewsModel[] = [];
  selectedItem: NewsModel | undefined;
  dialogVisible = false;


  ngOnInit() {
    this.getNews();
  }
  async getNews() {
    try {
      this.notificationsService.getUnreadMessages().subscribe((news) => {
        this.items = news;
      });
    }
    catch (error) {
      console.log(error);
    }
  }
  openMessage(item: NewsModel) {
    this.selectedItem = item;
    this.openDialog();
  }
  openDialog() {
    this.dialogVisible = true;
  }
  hideDialog() {
    this.dialogVisible = false;
  }
  closeDropdown() {
    this.isDropdownOpen = false;
  }
  toggleDropdown() {
    if(!this.isDropdownOpen){
      return this.openDropdown()
    }
    this.isDropdownOpen = false;
  }  
  openDropdown() {
    if (this.items.length == 0){
      this.router.navigate(['news']);
      return
    } 
    this.isDropdownOpen = true;
  }
}
