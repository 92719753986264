import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StoreService } from 'src/app/core/services/store.service';
import { AuthenticationModel, CustomerModel } from 'src/app/core/api/v1';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { GTService } from 'src/app/core/services/gt.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-customerdropdown',
  template: `
    <div *ngIf="permission && permission.authenticationLevel == 'Admin' && filteredCustomers" class="d-flex align-items-center">
      <p-autoComplete [(ngModel)]="currentCustomer" [dropdown]="true" [suggestions]="filteredCustomers" (completeMethod)="filter($event)" (onSelect)="onCustomerChange($event)" [dropdownicon]="" field="company" inputStyleClass="py-2 px-3" >
        <ng-template let-customer pTemplate="item">
          <li class="flex align-items-center">          
            <b class="mr-2">{{ customer.company }}</b>
            <span>{{ customer.domain }}</span>
          </li>
        </ng-template>
      </p-autoComplete>
      <p-button (click)="triggerFlow()" class="ml-3" [loading]="isLoading" label="Start Sync"></p-button>
    </div>
  `,
  styles: [
    `
    
    `,
  ],
})
export class CustomerDropDownComponent {
  @ViewChild(MatAutocompleteTrigger, { static: true })
  trigger: MatAutocompleteTrigger;
  currentCustomer: CustomerModel;
  filtervalue: string;
  customers: CustomerModel[];
  filteredCustomers: CustomerModel[];
  permission: AuthenticationModel;
  myControl = new FormControl();
  toastService: ToastrService
  isLoading = false;
  constructor(
    private storeService: StoreService,
    private authenticationService: AuthenticationService,
    private gtService: GTService,
    private toastr: ToastrService,
  ) {
    this.toastService = toastr;
    this.storeService.allCustomers.subscribe((customers: CustomerModel[]) => {
      this.customers = customers;
      this.filteredCustomers = customers;
    });
    this.storeService.currentCustomer.subscribe((customer: CustomerModel) => {
      this.currentCustomer = customer;
    });
    this.authenticationService.permission.subscribe(
      (permission: AuthenticationModel) => {
        this.permission = permission;
        try{
        if(permission && permission.authenticationLevel == 'Admin')
          this.storeService.requestAllCustomersAdmin(); 
                
        }
        catch(error:any){
          console.log(error)
        }
      }
    )}
  triggerFlow(){

    if(!this.currentCustomer.tenantId || this.currentCustomer.tenantId == '') return alert('No tenantId found for this customer')
    this.isLoading = true;
    // this.http.get("https://gt365devapp.azurewebsites.net/api/StartSyncSingle/0999f1ed-05ac-45c7-901c-09186b669550?code=QwV_tsQMqZs1iM5R4GYJV3KiNsdMt16fYDc9Squi7c8IAzFupl5Z4g==").subscribe(resp => alert(resp))
    this.gtService.triggerStartSyncSingle(this.currentCustomer.tenantId).subscribe((resp) => {
      this.toastr.success(resp)
      this.isLoading = false;
    })
  }

  filter(event: AutoCompleteCompleteEvent){
    console.log(this.currentCustomer)
    this.filteredCustomers = this.customers.filter((customer) => {
      return customer.company?.toLowerCase().includes(event.query.toLowerCase());
    });
  }

  onCustomerChange(customer: CustomerModel) {
    console.log(customer)
   this.currentCustomer = customer;
   if(!this.currentCustomer.tenantId || this.currentCustomer.tenantId == '') return alert('No tenantId found for this customer')
    this.storeService
      .requestCustomerAdmin(this.currentCustomer.tenantId)
      .then((customer: CustomerModel) => {
        this.storeService.updateCustomer(customer);
      });
  }

  
}