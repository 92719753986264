import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failed',
  template: `
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Failed</h1>
          <p>Something went wrong.</p>
        </div>
      </div>
    </div>
  `,
})
export class FailedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
