
import { Component} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdminService,  NewsModel } from 'src/app/core/api/v1';
import { fadeInOut } from 'src/app/styles/animation';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotificationService } from 'src/app/core/services/notifications.service.';



@Component({
  selector: 'app-admin-news',
  template: `
    <div  [@fadeInOut]>
      <div class="d-flex justify-content-between align-itemns-center">
        <h1 class="pageTitle"><i class="fas fa-newspaper fa-3x"></i>News</h1>
        <div class="d-flex justify-content-end align-items-center">
          <button
            type="button"
            class="btn btn-primary"
            (click)="handleAdd()"
          >
            New
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="handleEdit()"
            [disabled]="!selectedItem"
          >
            Edit
          </button>
          <button
            type="button"
            class="btn btn-danger"
            (click)="handleDelete()"
            [disabled]="!selectedItem"
          >
            Delete
          </button>
          <!-- <button
            type="button"
            class="btn btn-warning"
            (click)="resetForm()"
            [disabled]="!selectedItem"
          >
            Reset
          </button> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12" >
          <div class="table shadow-lg" [class.active]="news.length > 0" *ngIf="news">
            <div class="table-header">
              <div class="table-row">
                <div class="table-cell" style="width: 32px;">#</div>
                <div class="table-cell" style="flex: 1;">Title</div>
                <div class="table-cell" style="flex: 1;">Date</div>
                <div class="table-cell text-center" style="width: 100px;">Is Active</div>
              </div>
            </div>
            <div class="table-body">
              <div
                class="table-row"
                *ngFor="let news of news; let i = index"
                (click)="selectNews(news)"
                (keyup.enter)="selectNews(news)"
                [tabindex]="i"
                [class.selected]="selectedItem == news"
              >
                <div class="table-cell" style="width: 32px;">{{ i + 1 }}</div>
                <div class="table-cell" title="{{ news.title }}" style="flex: 1;">{{ news.title }}</div>
                <div class="table-cell" title="{{ news.date }}" style="flex: 1;">{{ news.date | date: "YYYY-MM-dd" }}</div>
                <div class="table-cell text-center" style="width: 100px;"><bool-icon [value]="news.isActive!" /></div>
              </div>
            </div>
          </div>
        </div>
        <p-dialog [header]="mode + ' Message'" [(visible)]="dialogVisible"  [modal]="true" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" [maximizable]="true" >
          <form
            [formGroup]="form"
            class="azure-templates-form"
            enctype="multipart/form-data"
            (submit)="handleSubmit($event)"
          >
            <div class="form-group col-12">
              <label for="title">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  formControlName="title"
                />
            </div>
            <div class="d-flex flex-wrap">
              <div class="form-group col-3 col-md-2">
                <label for="isActive">Is Active</label>
                <div class="mt-1">
                  <p-inputSwitch formControlName="isActive" id="isActive" ></p-inputSwitch>
                </div>
              </div>
              <div class="form-group col-9 col-md-10">
                <label for="date">Date</label>
                <input
                  type="date"
                  class="form-control"
                  id="date"
                  formControlName="date"
                  
                />
              </div>
            </div>
            <div class="form-group col-12">
              <label for="message">Message</label>
              <textarea
                type="text"
                class="form-control"
                id="message"
                rows="3"
                formControlName="message"
                (input)="handleCheckMessageLength()"
              >{{selectedItem?.message}}</textarea>
              <div class="d-flex justify-content-end w-100">
                  <span [class.text-danger]="messageLength > maxLength">{{messageLength}} </span>
                  <span> / {{maxLength}}</span>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="isActive">Is Active</label>
              <div>
                <p-inputSwitch formControlName="isActive" id="isActive"></p-inputSwitch>
              </div>
              <input
                type="checkbox"
                class="form-check-input"
                id="isActive"
                formControlName="isActive"
              />
            </div> -->
          
            
            <div class="w-100 d-flex justify-content-end">
              <button 
              type="submit"
                class="btn btn-primary focus" 
                [disabled]="isFormValid()">
                Submit
              </button>
            </div>
          </form>
        </p-dialog>
       <p-dialog header="Delete Solution {{selectedItem.title}}" [(visible)]="deleteDialogVisible" *ngIf="selectedItem" [modal]="true" [draggable]="false" [resizable]="false" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }">
         <p class="text-center" style="font-size: 18px;">Are you sure you want to delete?</p>
         <div class="d-flex justify-content-end">
            <button class="btn btn-primary mr-2" (click)="deleteNews()">Delete</button>
            <button class="btn btn-danger" (click)="hideDialog()">Cancel</button>
         </div>
       </p-dialog>
        
      </div>
    </div>
  `,
  styles: [
    `
      
      .table{
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      .table.active{
        opacity: 1;
      }
    
  
      .news-form .form-control {
        border-radius: 0.25rem;
      }
  
      .news-form .form-group label {
        font-weight: bold;
      }
  
      .news-form .form-group textarea {
        resize: none;
      }
      button {
        margin-right: 0.5em;
      }
    `,
  ],
  animations: [fadeInOut],
})
export class AdminNewsComponent {
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private adminService: AdminService,
    private authService: AuthenticationService,
    private notificationService: NotificationService
  ) {
    this.authService.requestPermission()
  }
  mode: 'New' | 'Edit' = 'New';
  form: FormGroup;
  news: NewsModel[];
  selectedItem: NewsModel | undefined;
  messageLength: number;
  maxLength = 6000
  dialogVisible = false
  deleteDialogVisible = false

  async ngOnInit () {
    this.getAllNews();
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
      date: ['', Validators.required],
      isActive: [false],
    });
    this.handleCheckMessageLength();
  }
  
  selectNews(news: NewsModel) {
    this.selectedItem = news;
    news.date = formatDate(new Date(news.date!), 'yyyy-MM-dd', 'en');
    this.form.patchValue(news);
  }
  resetForm() {
    this.form.reset({
      date: formatDate(new Date(), 'yyyy-MM-dd', 'en')
    });
    this.selectedItem = {};
  }
  getParams() {
    return {
      title: this.form.get('title')?.value,
      message: this.form.get('message')?.value,
      isActive: this.form.get('isActive')?.value,
      date: this.form.get('date')?.value,
    };
  }
  showDialog() {
    this.dialogVisible = true;
  }
  handleAdd(){
    this.selectedItem = undefined;
    this.resetForm();
    this.mode = 'New';
    this.showDialog();
  }
  isFormValid(){
    const formValidated = this.form.valid;
    const messageLengthValid = this.messageLength <= this.maxLength;
    return !(formValidated && messageLengthValid);
  }
  handleEdit(){
    this.mode = 'Edit';
    this.showDialog();
    this.handleCheckMessageLength();
  }
  handleDelete(){
    this.deleteDialogVisible = true;
  }
  handleSubmit(event: Event) {
    event.preventDefault();
    if (this.mode === 'New') {
      this.addNews();
    } else {
      this.updateNews();
    }
  }
  
  hideDialog() {
    this.dialogVisible = false;
    this.deleteDialogVisible = false;
  }
  handleCheckMessageLength() {
    this.messageLength = this.form.get('message')?.value.length || 0;
  }
  async addNews() {
    try {
      this.spinner.show();
      this.toastr.info('Adding news...');
     const { title, message, isActive, date } = this.getParams()
      this.adminService
        .apiAdminCreateNewsPost(message, title, isActive, date)
        .subscribe(data => {
          this.spinner.hide();
          this.toastr.success('News added successfully');
          this.getAllNews();
          this.resetForm()
          this.hideDialog();
          this.notificationService.fetchMessages();

        })
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Error adding news');
    }
  }
  async updateNews() {
    try {
      this.spinner.show();
      const { title, message, isActive, date } = this.getParams();
      
      this.adminService
        .apiAdminUpdateNewsPut(
          this.selectedItem?.newsId!,
          message,
          title,
          isActive ,
          date,
          this.selectedItem?.isRead,
        )
        .subscribe(data => {
          this.spinner.hide();
          this.toastr.success('News updated successfully');
          this.resetForm()
          this.hideDialog();
          this.notificationService.fetchMessages();
          this.getAllNews()
        })
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Error updating news');
    }
  }

  

  async deleteNews() {
    try {
      this.spinner.show();
      this.adminService
        .apiAdminDeleteNewsDelete(this.selectedItem?.newsId!)
        .subscribe(data => {
          this.spinner.hide();
          this.toastr.success('News deleted successfully');
          this.getAllNews();
          this.hideDialog();
          this.notificationService.fetchMessages();
        })
    } catch (error) {
      this.spinner.hide();
      this.toastr.error('Error deleting news');
    }
  }

  async getAllNews() {
    try {
      this.spinner.show();
      this.adminService.apiAdminGetAllNewsGet().subscribe(data => {
        this.news = data;
      this.spinner.hide();
      })
    } catch (error) {
      this.spinner.hide();
    }
  }
}
