import { routes } from './../routes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../core/services/authentication.service';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-unathorized',
  template: `
  <div class="d-flex align-items-center justify-content-center h-100 w-100" style="background: #f8f9fa;">
    <div class="d-flex align-items-center flex-column flex-md-row">
      <div class="col-8 col-md-4 mb-4 mb-md-0">
        <img src="assets/img/notfound.png" [alt]="type" class="w-100" />
      </div>
      <div class="d-flex flex-column align-items-center align-items-md-end col-12 col-md-8">
        <p class="message">
          {{message}}
        </p>
        <!-- <button class="btn btn-outline-primary w-100 m-auto m-md-0 col-10 col-sm-3" (click)="goBack()" *ngIf="type != 'NOT_ALLOWED'">Go Back</button> -->
        <button class="btn btn-outline-primary w-100 m-auto m-md-0 col-10 col-sm-3" (click)="goBack()" *ngIf="type == 'NOT_ALLOWED'">Contact Admin</button>
        <button class="btn btn-outline-primary w-100 m-auto m-md-0 col-10 col-sm-3" (click)="logOut()">Sign Out</button>

      </div>
    </div>
  </div>
  `,
  styles: [
    `
      .message {
         font-size: 1.5rem; font-weight: 500; 
      }
      @media (max-width: 768px) {
        .message {
          font-size: 1.2rem;
          text-align: center;
        }
      }
    `
  ]
})
export class UnathorizedComponent implements OnInit {
    message: string;
    type: string;
    page = "";
    constructor(private route: ActivatedRoute, private location: Location, private authService: MsalService ) { }
    ngOnInit(): void {
      this.route.data.subscribe(data => {
          this.message = MESSAGES[data.type];
          this.type = data.type;
      });
    }
    goBack() {
      this.location.back();
    }
    logOut(popup?: boolean) {
      if (popup) {
        this.authService.logoutPopup({
          mainWindowRedirectUri: '/',
        });
      } else {
        this.authService.logoutRedirect();
      }
      this.authService.logout();
    }


}

const MESSAGES:{
  [key: string] : string;
} = {
  UNAUTHORIZED: 'Sorry, your tenant has not been found to be an active customer of GT365. Please open a new trial if you wish to evaluate the solution with your M365 tenant.',
  UNAPPROVED: 'Your account has not been approved yet.',
  NOT_ALLOWED: 'You are not allowed to access this page. Please contact your administrator.',
};


