import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NewsModel } from '../api/v1';
import { NotificationService } from '../services/notifications.service.';


@Component({
  selector: 'app-notification-dialog',
  template: `
    <p-dialog
      [focusOnShow]="true"
      [focusTrap]="true"
      [(visible)]="dialogVisible"
      *ngIf="selectedItem"
      [modal]="true"
      [draggable]="false"
      [resizable]="false"
      [breakpoints]="{ '960px': '75vw' }"
      [style]="{ width: '50vw' }"
      (onHide)="handleCloseDialog()"
      >
      <ng-template pTemplate="header" >
        <div class="d-flex flex-column">
          <h4 class="mb-1">{{selectedItem.title}}</h4>
          <span class="text-secondary">Created on: {{ selectedItem.date }}</span>
        </div>
      </ng-template>
      
      <div class="pr-2">
        <p class="text-justify" style="font-size: 17px;" [innerHTML]="selectedItem.message"></p>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary mr-2" (click)="handleChangeStatus()">{{buttonText()}}</button>
            <button class="btn btn-danger" (click)="handleCloseDialog()">Close</button>
          </div>

        </div>
    </p-dialog>
    `,
})
export class NotificationDialogComponent {
  @Input() selectedItem: NewsModel;
  @Input() dialogVisible: boolean;
  @Output() onDialogVisibleChange = new EventEmitter<boolean>();
  @Output() onChangedRead = new EventEmitter<boolean>();
  constructor(private notificationService: NotificationService, private cd: ChangeDetectorRef) {}
  ngOnInit() {
  }
  
  handleChangeStatus() {
    this.selectedItem.isRead ? this.handleMarkAsUnread(): this.handleMarkAsRead();
  }


  handleMarkAsRead() {
    if(!this.selectedItem.newsId) return 
    this.notificationService.markAsRead(this.selectedItem.newsId).then(() => {
      this.onChangedRead.emit(true);
    });
  }

  handleMarkAsUnread  () {
    if(!this.selectedItem.newsId) return
    this.notificationService.markAsUnread(this.selectedItem.newsId).then(() => {
      this.onChangedRead.emit(false);
    });
  }
  handleCloseDialog() {
    this.onDialogVisibleChange.emit(false);
  }
  buttonText() {
    return this.selectedItem.isRead ? 'Mark as unread' : 'Mark as read';
  }
}